import { getQueue } from "../../queues/getQueue"
import { Entitlement, LiteformsUsage, PrismaClient } from "@prisma/client"
import moment from "moment"
import { CreateImageRequestSizeEnum, ImagesResponse } from "openai"
import { InfluxDbWriterJob } from "../../queues/jobs/influxDbWriter"
import { Entitlements } from "./Entitlements.model"

// todo this will need to tie to the user's subscription renewal date
const USAGE_WINDOW_MONTHS = 1

const getMaxSecondsByTier = (ent?: Entitlement): number => {
	// 8/29/24 Temporary update to the default limit to have the free tier = Plus for the initial Go shipments
	const defaultBasicLimit = 60 * 60 * 5.5 // 60 * 30
	if (!ent) return defaultBasicLimit
	const { type, source } = ent
	switch (type) {
		case "LITEFORMS_BASIC":
			if (source === "CHARGEBEE") {
				return 60 * 60 * 5.5
			} else return defaultBasicLimit
		case "LITEFORMS_PLUS":
			return 60 * 60 * 5.5
		case "LITEFORMS_CREATOR":
			return 60 * 60 * 11
		case "LITEFORMS_PRO":
			return 60 * 60 * 110
		default:
			return defaultBasicLimit
	}
}

async function syncUsageToHubspot(userId: number) {
	getQueue("hubspotLiteformsSync").add({ userId }, { jobId: userId })
}

export function LiteformsUsageModel(prisma: PrismaClient) {
	return Object.assign(prisma, {
		async addGptTokens({
			userId,
			totalGPTTokens,
			totalCostUSD,
			model,
		}: {
			userId: number
			totalGPTTokens: number
			totalCostUSD: number
			model: string
		}): Promise<LiteformsUsage> {
			let usage = await this.getRecentUsage(userId)

			if (!usage) {
				throw new Error("No Usage Found")
			}

			const update = await prisma.liteformsUsage.update({
				where: { id: usage.id },
				data: {
					totalGPTTokens: {
						increment: totalGPTTokens,
					},
				},
			})

			if (totalGPTTokens > 0) {
				await Promise.all([
					syncUsageToHubspot(userId),
					InfluxTrackUsage({ userId, name: "gptTokens", value: totalGPTTokens }),
					// todo use actual model name
					InfluxTrackCosts({
						userId,
						name: model,
						value: totalCostUSD,
					}),
				])
			}

			return update
		},

		async addSTTUsage(userId: number, seconds: number): Promise<LiteformsUsage> {
			let usage = await this.getRecentUsage(userId)

			if (!usage) {
				throw new Error("No Usage Found")
			}

			const update = await prisma.liteformsUsage.update({
				where: { id: usage.id },
				data: {
					totalSTTSeconds: {
						increment: seconds,
					},
				},
			})

			if (seconds > 0) {
				await Promise.all([
					syncUsageToHubspot(userId),
					InfluxTrackUsage({ userId, name: "sttSeconds", value: seconds }),
					InfluxTrackCosts({ userId, name: "stt", value: (seconds / 60 / 24) * 1.4 }),
				])
			}

			return update
		},

		async addTTSUsage(userId: number, seconds: number, characters: number = 0): Promise<LiteformsUsage> {
			let usage = await this.getRecentUsage(userId)

			if (!usage) {
				throw new Error("No Usage Found")
			}

			const updated = await prisma.liteformsUsage.update({
				where: { id: usage.id },
				data: {
					totalTTSSeconds: {
						increment: seconds,
					},
					totalTTSChars: {
						increment: characters,
					},
				},
			})

			if (characters > 0) {
				await Promise.all([
					syncUsageToHubspot(userId),
					InfluxTrackUsage({ userId, name: "ttsSeconds", value: seconds }),
					InfluxTrackUsage({ userId, name: "ttsChars", value: characters }),
					// $16 per 1M characters
					InfluxTrackCosts({ userId, name: "tts", value: (characters / 1000000) * 16 }),
				])
			}

			return updated
		},

		async trackImageGenerations(args: {
			userId: number
			size: CreateImageRequestSizeEnum
			images: ImagesResponse
			usage: { id: number }
		}) {
			const { userId, size, images } = args
			const sizeCosts = {
				"1024x1024": 0.02,
				"512x512": 0.018,
				"256x256": 0.016,
			}

			const totalImages = images.data.length

			if (totalImages > 0) {
				const updated = await prisma.liteformsUsage.update({
					where: { id: args.usage.id },
					data: {
						totalImages: {
							increment: totalImages,
						},
					},
				})

				await Promise.all([
					InfluxTrackUsage({ userId, name: "images", value: totalImages }),
					InfluxTrackCosts({ userId, name: "images", value: totalImages * sizeCosts[size] }),
				])

				return updated
			}

			return null
		},

		/** This should only be called on a user if they have early  access */
		async getRecentUsage(userId?: number): Promise<LiteformsUsage | null> {
			if (!userId) return null

			const usage = await prisma.liteformsUsage.findFirst({
				where: {
					userId,
					startDate: {
						gte: moment().subtract(USAGE_WINDOW_MONTHS, "month").toDate(),
					},
				},
			})

			if (!usage) {
				await syncUsageToHubspot(userId)

				return await prisma.liteformsUsage.create({
					data: { startDate: new Date(), userId },
				})
			}

			return usage
		},

		async calculateUsage(args: {
			user: { id: number; maxLiteformsUsageSeconds: number | null }
			usage?: LiteformsUsage | null
			entitlements?: Entitlement[] | null
		}) {
			const usage = args.usage ?? (await this.getRecentUsage(args.user.id))

			if (!usage) {
				throw new Error("No Usage Found")
			}

			let entitlements = args.entitlements ?? (await Entitlements(prisma).getAllActive(args.user))

			const rechargeEnt = entitlements.find((e) => e.source === "RECHARGE")
			const chargebeeEnt = entitlements.find((e) => e.source === "CHARGEBEE")

			// Get the max seconds from the user's entitlements
			let maxSeconds =
				args.user.maxLiteformsUsageSeconds ??
				getMaxSecondsByTier(chargebeeEnt ?? rechargeEnt ?? entitlements[0])

			const totalSeconds =
				(usage.totalGPTTokens ?? 0) / 100 + (usage.totalSTTSeconds ?? 0) + (usage.totalTTSSeconds ?? 0)

			let totalSecondsRemaining = maxSeconds - totalSeconds
			if (totalSecondsRemaining < 0) {
				totalSecondsRemaining = 0
			}

			return {
				model: usage,
				entitlements,
				data: {
					totalSeconds,
					totalSecondsRemaining,
					totalGPTTokens: usage.totalGPTTokens ?? 0,
					totalSTTSeconds: usage.totalSTTSeconds ?? 0,
					totalTTSSeconds: usage.totalTTSSeconds ?? 0,
					totalTTSChars: usage.totalTTSChars ?? 0,
					totalImagesCreated: usage.totalImages ?? 0,
					startedAt: usage.startDate,
					resetsAt: moment(usage.startDate).add(1, "month").toDate(),
				},
			}
		},
	})
}

async function InfluxTrackCosts({ userId, name, value }: { userId: number; name: string; value: number }) {
	if (value <= 0) return
	const fields = {}
	fields[name] = value
	fields["userId"] = userId.toString()
	await InfluxDbWriterJob.queue.add({
		measurement: "liteformsCosts",
		timestamp: new Date().toISOString(),
		fields: fields,
	})
}

async function InfluxTrackUsage({ userId, name, value }: { userId: number; name: string; value: number }) {
	if (value <= 0) return
	const fields = {}
	fields[name] = value
	fields["userId"] = userId.toString()
	await InfluxDbWriterJob.queue.add({
		measurement: "liteformsUsage",
		timestamp: new Date().toISOString(),
		fields: fields,
	})
}
