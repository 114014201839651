import React from "react"
import { SVGProps } from "react"

export function QuestionMarkIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
			<path
				fill="#fff"
				d="M7.67 7.04c-.8-.34-1.14-1.33-.66-2.05.97-1.44 2.59-2.49 4.73-2.49 2.35 0 3.96 1.07 4.78 2.41.7 1.15 1.11 3.3.03 4.9-1.2 1.77-2.35 2.31-2.97 3.45-.15.27-.24.49-.3.94-.09.73-.69 1.3-1.43 1.3-.87 0-1.58-.75-1.48-1.62.06-.51.18-1.04.46-1.54.77-1.39 2.25-2.21 3.11-3.44.91-1.29.4-3.7-2.18-3.7-1.17 0-1.93.61-2.4 1.34-.35.57-1.08.75-1.69.5Zm6.08 12.46c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2Z"
			/>
		</svg>
	)
}
