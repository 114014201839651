import { EntitlementPeriod, EntitlementSource, EntitlementType, PrismaClient } from "@prisma/client"
import moment from "moment"
import { Subscription } from "server/routers/recharge/types"

const getEntitlementType = (tier: string): EntitlementType | null => {
	switch (tier) {
		case "Plus":
			return "LITEFORMS_PLUS"
		case "Creator":
			return "LITEFORMS_CREATOR"
		case "Pro":
			return "LITEFORMS_PRO"
		default:
			return null
	}
}

const getEntitlementPeriod = (period: string): EntitlementPeriod | null => {
	if (period === "Monthly") {
		return "MONTH"
	} else if (period === "Annual") {
		return "YEAR"
	}
	return null
}
const getEntitlementInfo = (
	sub: Subscription,
): { type: EntitlementType; period: EntitlementPeriod; subscriptionId?: string; startsAt: Date } | null => {
	const title = sub.product_title
	const regex = /^(\w+).*\((\w+)\)/
	const matches = title?.match(regex) as string[]

	if (!matches) return null
	const [_, tier, freq] = matches
	const type = getEntitlementType(tier)
	const period = getEntitlementPeriod(freq)

	if (!type || !period) return null
	const subscriptionId = sub.id?.toString()

	const startsAt = sub.created_at ? new Date(sub.created_at) : new Date()

	return { type, period, subscriptionId, startsAt }
}
export function Entitlements(prisma: PrismaClient) {
	return Object.assign(prisma, {
		async findActiveEntitlement(userId: number, type: EntitlementType) {
			return await prisma.entitlement.findFirst({
				where: {
					userId,
					type,
					isEnabled: true,
					startsAt: { lte: new Date() },
				},
			})
		},

		async getAllActive(args: { id: number }) {
			const currentTimestamp = new Date()
			return await prisma.entitlement.findMany({
				where: {
					userId: args.id,
					isEnabled: true,
					OR: [
						{ expiresAt: null }, // expiresAt is null
						{ expiresAt: { gt: currentTimestamp } }, // expiresAt is greater than current time
					],
					startsAt: { lte: currentTimestamp },
				},
			})
		},

		async removeAll(args: { id: number }) {
			return await prisma.entitlement.deleteMany({
				where: {
					userId: args.id,
				},
			})
		},

		async add(args: {
			userId: number
			type: EntitlementType
			expiresAt?: Date
			subscriptionId?: string
			startsAt?: Date
			source: EntitlementSource
			period?: EntitlementPeriod
		}) {
			const search = await this.findActiveEntitlement(args.userId, args.type)
			if (search) {
				console.error("User already has this entitlement")
			}

			return await prisma.entitlement.create({
				data: {
					...args,
					isEnabled: true,
					startsAt: args.startsAt ?? new Date(),
					expiresAt: args.expiresAt,
					source: args.source,
				},
			})
		},

		async addFromRecharge(sub: Subscription) {
			const { email } = sub
			const user = await prisma.user.findFirst({ where: { email: sub.email } })
			if (!user) {
				console.warn("Missing User for Email: " + sub.email)
				return
			}

			const subInfo = getEntitlementInfo(sub)
			if (!subInfo) {
				console.warn("Not a valid recharge subscription")
				return
			}

			// Only search for existing Recharge ents.
			const existingSub = await prisma.entitlement.findFirst({
				where: { user: { email }, source: "RECHARGE" },
			})

			if (existingSub) {
				const subMismatch = sub.id?.toString() !== existingSub.subscriptionId?.toString()
				if (subMismatch) {
					return
					// throw new Error(`New sub ${sub.id} doesn't match existing sub ${existingSub.subscriptionId}`)
				}

				//Check if plan type was updated
				if (subInfo.type === existingSub.type) return

				//Update the existing record to the new plan
				await prisma.entitlement.update({ where: { id: existingSub.id }, data: subInfo })
			} else {
				const createdSub = await prisma.entitlement.create({
					data: { source: "RECHARGE", ...subInfo, userId: user.id },
				})
				return createdSub
			}
		},

		async addFromEarlyAccess(args: {
			userId: number
			earlyAccess: { id: number; expiresInMonths: number | null; entitlementType: EntitlementType }
		}) {
			try {
				await this.add({
					userId: args.userId,
					expiresAt: moment().add(args.earlyAccess.expiresInMonths, "months").toDate(),
					type: args.earlyAccess.entitlementType,
					source: "SHOPIFY",
				})
			} catch (e) {
				if (!(e as Error).message.match(/User already has/)) {
					throw e
				} else {
					// Ignore if the user already has this entitlement
					console.warn(e)
				}
			}

			// Remove the reference to entitlements in the early access list
			await prisma.liteformsEarlyAccess.update({
				where: { id: args.earlyAccess.id },
				data: { entitlementType: null, expiresInMonths: null },
			})
		},

		async updateFromRecharge(sub: Subscription) {
			const { email } = sub
			const existingSub = await prisma.entitlement.findFirst({ where: { user: { email } } })
			const info = getEntitlementInfo(sub)
			if (!info) {
				// Invalid recharge sub item
				return
			}
			if (existingSub) {
				await prisma.entitlement.update({ where: { id: existingSub.id }, data: info })
				return
			} else {
				throw new Error(`Failed to find sub ID ${sub.id}`)
			}
		},

		async hasEntitlement(userId: number, type: EntitlementType): Promise<boolean> {
			const userFlag = await this.findActiveEntitlement(userId, type)
			if (!userFlag || !userFlag.isEnabled) {
				return false
			}
			return true
		},

		async removeEntitlementByCustomerId(customerId: string) {
			const entitlement = await prisma.entitlement.findFirst({ where: { user: { subId: customerId } } })
			if (entitlement) {
				return await prisma.entitlement.delete({ where: { id: entitlement.id } })
			} else {
				console.warn(`Entitlement not found`)
			}
		},

		async removeFromRecharge(sub: Subscription) {
			const subscriptionId = sub.id?.toString()
			await prisma.entitlement.deleteMany({ where: { subscriptionId } })
		},
	})
}
