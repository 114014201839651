import { useUser } from "@auth0/nextjs-auth0/client"
import { User } from "@prisma/client"
import { setUser } from "@sentry/nextjs"
import { BlocksUserContext, createBlocksUserStore } from "hooks/useStore"
import { Auth0User } from "lib/createUser"
import { PropsWithChildren, useEffect, useRef } from "react"
import { useStore } from "zustand"
import { trpc } from "lib/trpc/trpc"
import { mapStringsToDates } from "lib/utils.user"

interface BlocksProviderArgs extends PropsWithChildren {
	// dbUser may exist after getServerSideProps
	// Will not be populated on static pages
	ssrUser?: User
}

export default function BlocksUserProvider(args: BlocksProviderArgs) {
	const { ssrUser, children } = args
	let { user, isLoading: isAuthLoading } = useUser()
	const {
		data: userData,
		refetch: refetchUserData,
		isFetching: isFetchingUserData,
	} = trpc.user.getUser.useQuery(undefined, {
		enabled: !!user,
		initialData: ssrUser ? JSON.parse(JSON.stringify(ssrUser)) : undefined,
	})
	const {
		data: currentData,
		refetch: refetchCurrentData,
		isFetching: isFetchingCurrentData,
	} = trpc.user.current.useQuery(undefined, {
		enabled: !!user,
	})

	const refetch = async () => {
		await refetchUserData()
		await refetchCurrentData()
	}

	// Initialize the User Store with data passed down from server side props
	const store = useRef(
		createBlocksUserStore({
			dbUser: ssrUser,
			authUser: user as Auth0User,
			isLoading: false,
			refetch,
		}),
	).current

	const authUser = useStore(store, (s) => s.authUser)
	const dbUser = useStore(store, (s) => s.dbUser)

	const setAuthUser = useStore(store, (s) => s.setAuthUser)
	const setIsLoading = useStore(store, (s) => s.setIsLoading)
	const setDbUser = useStore(store, (s) => s.setDbUser)
	const setCurrent = useStore(store, (s) => s.setCurrent)

	// *** Auth0 Management ***
	useEffect(() => {
		setAuthUser(user as Auth0User)
	}, [user])

	// This confirms that the Auth0 has been checked for and does not exist
	useEffect(() => {
		if (!user && !isAuthLoading) {
			setIsLoading(false)
		} else if (isAuthLoading) {
			setIsLoading(true)
		}
	}, [isAuthLoading, user])

	// Set Sentry User
	useEffect(() => {
		if (!authUser || !dbUser) return
		setUser({
			email: authUser?.email ?? undefined,
			username: dbUser?.username ?? undefined,
			id: dbUser?.id?.toString(),
		})
	}, [authUser, dbUser])

	useEffect(() => {
		setDbUser(mapStringsToDates(userData))
	}, [userData])

	useEffect(() => {
		setCurrent(currentData)
	}, [currentData])

	useEffect(() => {
		setIsLoading(isFetchingCurrentData || isFetchingUserData)
	}, [isFetchingCurrentData, isFetchingUserData])

	return <BlocksUserContext.Provider value={store}>{children}</BlocksUserContext.Provider>
}
