import { ApolloProvider } from "@apollo/client"
import { UserProvider } from "@auth0/nextjs-auth0/client"
import * as Tooltip from "@radix-ui/react-tooltip"
import { SmallGyroUI } from "components/embed/GyroUI"
import Layout from "components/Layout"
import BlocksUserProvider from "components/providers/BlocksUserProvider"
import BridgeProvider from "components/providers/BridgeProvider"
import HeaderStoreProvider from "components/providers/HeaderStoreProvider"
import { IncomingHttpHeaders } from "http"
import { Leva } from "leva"
import { useApollo } from "lib/apolloClient"
import { trpc } from "lib/trpc/trpc"
import type { NextPage } from "next"
import { AxiomWebVitals } from "next-axiom"
import { DefaultSeo } from "next-seo"
import type { AppProps } from "next/app"
import type { ReactElement, ReactNode } from "react"
import "../styles/globals.css"

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
	getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout
}

function BlocksApp({ Component, pageProps }: AppPropsWithLayout) {
	const { headers } = pageProps
	const apolloClient = useApollo(pageProps)

	const customLayout = Component.getLayout ?? null

	let { dbUser, authUser } = pageProps || {}

	return (
		<HeaderStoreProvider headers={headers as IncomingHttpHeaders}>
			<Leva hidden={process.env.LEVA_ENABLED !== "1"} />
			<UserProvider user={authUser}>
				<BlocksUserProvider ssrUser={dbUser}>
					<Tooltip.Provider>
						{/* GraphQL */}
						<ApolloProvider client={apolloClient}>
							<BridgeProvider>
								<DefaultSeo
									titleTemplate="%s | Looking Glass Blocks"
									defaultTitle="Blocks | Goodbye GIFs. Meet holograms."
									openGraph={{
										type: "website",
										title: "Blocks | Goodbye GIFs. Meet holograms.",
										site_name: "Looking Glass Blocks",
										description: "The first holographic sharing platform built for 3D creators.",
										url: process.env.BASE_URL,
										images: [
											{
												url: `https://${process.env.NEXT_AWS_CLOUDFRONT_DOMAIN}/web/social-blocks-banner2.png?w=1080&format=jpg`,
												width: 1080,
												height: 720,
											},
										],
									}}
									twitter={{
										handle: "@LKGGlass",
										site: "@LKGGlass",
										cardType: "summary_large_image",
									}}
									additionalMetaTags={[
										{
											name: "twitter:domain",
											content: process.env.BASE_URL!,
										},
									]}
								/>
								{customLayout ? (
									<>{customLayout(<Component {...pageProps} />)}</>
								) : (
									<Layout>
										<AxiomWebVitals />
										<Component {...pageProps} />
									</Layout>
								)}
							</BridgeProvider>

							<SmallGyroUI />
						</ApolloProvider>
					</Tooltip.Provider>
				</BlocksUserProvider>
			</UserProvider>
		</HeaderStoreProvider>
	)
}

export default trpc.withTRPC(BlocksApp)
