import { Transition, TransitionChild } from "@headlessui/react"
import { CogIcon, LinkIcon, WrenchIcon } from "@heroicons/react/24/outline"
import {
	ClockIcon,
	ExclamationTriangleIcon,
	EyeIcon,
	FaceFrownIcon,
	XMarkIcon,
} from "@heroicons/react/24/solid"
import { BannerState, useBannerStore } from "hooks/useBannerStore"
import { useCastStore } from "hooks/useCastStore"
import useHologramDeviceView from "hooks/useHologramDeviceView"
import { campaignGenerator, gtmEvent } from "lib/analytics"
import { classNames } from "lib/classNames"
import { timeout } from "lib/utils"
import { Fragment, useEffect, useState } from "react"
import { isMobile, isSafari } from "react-device-detect"
import { Linky } from "./Linky"
import SpinnerLoader from "./SpinnerLoader"

export interface DeviceConnectionBannerArgs {
	initOwner?: boolean
	initOpen?: boolean
	initBannerState?: BannerState
}

export function DeviceConnectionBanner({
	initBannerState = "first_time_connect",
	initOpen = false,
	initOwner = false,
}: DeviceConnectionBannerArgs) {
	const hologram = useCastStore((state) => state.hologram)
	const handleCastHologram = useCastStore((state) => state.handleCastHologram)
	const connection = useCastStore((state) => state.connection)
	const isError = useCastStore((state) => state.isError)
	const castStatus = useCastStore((state) => state.castStatus)
	const handleRetryDisplay = useCastStore((state) => state.handleRetryDisplay)
	const manuallyConnectToBridge = useCastStore((state) => state.manuallyConnectToBridge)
	const version = useCastStore((state) => state.version)
	const banner = useBannerStore()

	// storybook updates
	useEffect(() => {
		banner.setState(initBannerState)
		banner.setIsOwner(initOwner)
		banner.setOpen(initOpen)
	}, [initBannerState, initOpen, initOwner])

	const [pending, setPending] = useState<boolean>(false)

	async function onRetry() {
		if (!hologram) {
			return
		}
		setPending(true)
		const currentCast = await handleCastHologram(hologram.id)
		setPending(false)
	}

	async function onClose() {
		banner.setOpen(false)
		await timeout(900)
	}

	useEffect(() => {
		// If banner is closed or closing, dont change the state
		if (!banner.isOpen) return
		if (connection === "NOT_ESTABLISHED") return

		if (banner.isOpen && isMobile) {
			banner.setState("mobile")
			return
		}

		if (banner.isOpen && isSafari) {
			banner.setState("browser_not_supported")
			return
		}
	}, [isError, connection, castStatus, banner.isOpen])

	const isCastingHologram = castStatus === "ACTIVE" && hologram && hologram?.id
	useHologramDeviceView(hologram?.id, !isCastingHologram)

	return (
		<Transition
			show={banner.isOpen}
			as={"div"}
			className="fixed left-0 top-[74px] flex w-full flex-row items-center justify-center sm:block">
			<TransitionChild
				as={Fragment}
				enter="ease-out duration-500"
				enterFrom="opacity-0 -translate-y-5"
				enterTo="opacity-100"
				leave="ease-in duration-200"
				leaveFrom="opacity-100"
				leaveTo="opacity-0">
				<div className=" mx-auto flex max-w-screen-2xl flex-row justify-center">
					<div
						className={classNames(
							"mx-4 max-h-32 w-full overflow-clip rounded-xl bg-gradient-to-r from-[#5901FB] to-[#9E53FB] text-sm text-white",
							banner.state === "connected" || banner.state === "pending" ? "" : "py-4",
						)}>
						<div className="mx-auto flex w-full max-w-7xl flex-row space-x-4 px-2 sm:px-6">
							{banner.state === "first_time_connect" && (
								<BannerContents
									icon={<LinkIcon className="w-6" />}
									title="Connect to holographic display"
									description="Do you own a Looking Glass Display?"
									buttons={
										<>
											<button
												className="rounded-xl bg-white px-8 py-4 font-bold text-black"
												onClick={() => {
													banner.setState("info")
												}}>
												No
											</button>
											<button
												className="rounded-xl bg-black px-8 py-4 font-bold"
												onClick={() => {
													banner.setState("retry")
													banner.setIsOwner(true)
													onRetry()
												}}>
												<span className="holo-light">Yes</span>
											</button>
										</>
									}
								/>
							)}

							{(banner.state === "info" || banner.state === "mobile") && (
								<BannerContents
									icon={<LinkIcon className="w-6" />}
									title="Learn about Looking Glass Displays"
									description="Discover the amazing features and products of Looking Glass."
									buttons={
										<Linky
											href="https://lookingglassfactory.com/product-overview"
											target="_blank"
											className="rounded-xl bg-black px-8 py-4 font-bold"
											onClick={() => gtmEvent("learn-more-btn")}>
											Learn More
										</Linky>
									}
								/>
							)}

							{banner.state === "no_display" && (
								<BannerContents
									icon={
										pending ? <CogIcon className="w-6 animate-spin" /> : <FaceFrownIcon className="w-6" />
									}
									title={pending ? "Connecting to display..." : "No Looking Glass found"}
									description={
										pending
											? "Searching for Looking Glass Display"
											: "Looks like Bridge is running, but we can't find your Looking Glass"
									}
									buttons={
										<>
											<Linky
												href={campaignGenerator(
													"https://lookingglassfactory.com/onboarding-instructions/looking-glass-portrait",
													"guide",
													"onboarding",
												)}
												target="_blank"
												className="rounded-xl bg-white px-8 py-4 font-bold text-black"
												onClick={() => {
													gtmEvent("cast-setup-guide-btn")
												}}>
												Setup guide
											</Linky>
											<button
												className="rounded-xl bg-black px-8 py-4 font-bold"
												onClick={async () => {
													setPending(true)
													const retry = await handleRetryDisplay()
													setPending(false)
												}}>
												<span className="holo-light">Try again</span>
											</button>
										</>
									}
								/>
							)}

							{banner.state === "generating" && (
								<BannerContents
									icon={
										<div className={classNames("relative h-12 w-12 rounded-full")}>
											<WrenchIcon className="w-6" />{" "}
											<SpinnerLoader
												className="absolute inset-0 -ml-3 -mt-3 -translate-x-[1px] -translate-y-[1px]"
												color={"#E649F5"}
												speed={200}
												thickness={100}
											/>
										</div>
									}
									title="Generating..."
									description="This hologram is still generating, please be patient"
									buttons={<></>}
								/>
							)}

							{banner.state === "cant_connect" && (
								<BannerContents
									icon={
										pending ? <CogIcon className="w-6 animate-spin" /> : <FaceFrownIcon className="w-6" />
									}
									title={pending ? "Reconnecting..." : "Unable to connect"}
									description={
										pending
											? "Attempting to reconnect"
											: "Make sure your display is in Desktop Mode and Bridge is running."
									}
									buttons={
										<>
											<Linky
												href={campaignGenerator(
													"https://lookingglassfactory.com/onboarding-instructions/looking-glass-portrait",
													"guide",
													"onboarding",
												)}
												target="_blank"
												className="rounded-xl bg-white px-8 py-4 font-bold text-black"
												onClick={() => {
													gtmEvent("cast-setup-guide-btn")
												}}>
												Setup guide
											</Linky>
											<button
												className="rounded-xl bg-black px-8 py-4 font-bold"
												onClick={async () => {
													setPending(true)
													const reconnect = await manuallyConnectToBridge()
													setPending(false)
												}}>
												<div className="flex flex-row">
													<span className="holo-light">Try again</span>
													{pending && (
														<SpinnerLoader
															className="h-6 w-6"
															color={"#E649F5"}
															speed={200}
															thickness={100}
														/>
													)}
												</div>
											</button>
										</>
									}
								/>
							)}

							{banner.state === "disconnected" && (
								<BannerContents
									icon={
										pending ? <CogIcon className="w-6 animate-spin" /> : <FaceFrownIcon className="w-6" />
									}
									title={pending ? "Reconnecting..." : "Disconnected from Bridge"}
									description={
										pending
											? "Reconnecting..."
											: "Looks Like Bridge is no longer running. Please restart Bridge and try again."
									}
									buttons={
										<>
											<Linky
												href={campaignGenerator(
													"https://docs.lookingglassfactory.com/software/looking-glass-bridge",
													"guide",
													"onboarding",
												)}
												target="_blank"
												className="rounded-xl bg-white px-8 py-4 font-bold text-black"
												onClick={() => {
													gtmEvent("cast-setup-guide-btn")
												}}>
												Setup guide
											</Linky>
											<button
												className="rounded-xl bg-black px-8 py-4 font-bold"
												onClick={async () => {
													setPending(true)
													const reconnect = await manuallyConnectToBridge()
													setPending(false)
												}}>
												<span className="holo-light">Reconnect</span>
												{pending && (
													<SpinnerLoader
														// className="absolute inset-0 -ml-3 -mt-3 -translate-x-[1px] -translate-y-[1px]"
														color={"#E649F5"}
														speed={200}
														thickness={100}
													/>
												)}
											</button>
										</>
									}
								/>
							)}

							{banner.state === "outdated" && (
								<BannerContents
									icon={<ClockIcon className="w-6" />}
									title={`Software update recommended`}
									description={`You're running an older version of our driver, Please Update to the latest version.`}
									buttons={
										<Linky
											href={campaignGenerator(
												"https://lookingglassfactory.com/software/looking-glass-bridge",
												"bridge",
												"update",
											)}
											target="_blank"
											className="rounded-xl bg-black px-8 py-4 font-bold"
											onClick={() => {
												gtmEvent("cast-update-btn")
											}}>
											Download Bridge
										</Linky>
									}
								/>
							)}

							{banner.state === "connected" && (
								<div className="flex flex-1 place-items-center space-x-4">
									<div className="w-full text-right font-bold">
										{hologram && (
											<>
												Casting&nbsp;
												{/* <Linky href={cast.hologram.permalink} className="underline"> */}
												{hologram.title}
												{/* </Linky> */}
											</>
										)}
										{!hologram && "Connected!"}
									</div>
								</div>
							)}

							{banner.state === "pending" && (
								<div className="flex flex-1 place-items-center space-x-4">
									<div className="w-full text-right font-bold">{!hologram && "casting..."}</div>
								</div>
							)}

							{banner.state === "browser_not_supported" && (
								<BannerContents
									icon={<ExclamationTriangleIcon className="w-6" />}
									title="Browser not supported"
									description="Please use Firefox or Chrome on a desktop computer in order to connect to your Looking Glass display."
									buttons={<></>}
								/>
							)}

							{banner.state === "retry" && (
								<BannerContents
									icon={<CogIcon className="w-6 animate-spin" />}
									title="Retrying to connect..."
									description="We are trying to reconnect to your Looking Glass Display. You can also visit our troubleshooting page."
									buttons={
										<Linky
											href="https://docs.lookingglassfactory.com/software-tools/looking-glass-bridge"
											target="_blank"
											className="rounded-xl bg-white px-8 py-4 font-bold text-black"
											onClick={() => gtmEvent("troubleshooting-help")}>
											Troubleshooting
										</Linky>
									}
								/>
							)}

							<div className="flex place-items-center">
								<button className="p-2" onClick={onClose}>
									<XMarkIcon className="w-6" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</TransitionChild>
		</Transition>
	)
}

interface ContentArgs {
	icon?: JSX.Element
	title: string
	description?: JSX.Element | string
	buttons?: JSX.Element
}

function BannerContents(args: ContentArgs) {
	return (
		<>
			<div className="flex flex-1 flex-col space-y-2 px-2 md:flex-row md:space-y-0 md:px-0">
				{/* left side */}
				<div className="flex flex-1 flex-row place-items-center md:space-x-4">
					{/* icon  */}
					<div className="hidden h-12 w-12 rounded-full bg-white bg-opacity-20 p-3 md:inline-block">
						{args.icon}
					</div>

					<div className="flex-1">
						<h1 className="text-lg font-bold md:text-2xl">{args.title}</h1>
						<div className="text-xs md:text-sm">{args.description}</div>
					</div>
				</div>
				{/* Right side Buttons/CTAs */}
				<div className="flex flex-row place-items-center space-x-2">{args.buttons}</div>
			</div>
		</>
	)
}
