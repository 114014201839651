import { CurrentUserResult } from "@/server/routers/user.trpc"
import { User } from "@prisma/client"
import { SupabaseClient } from "@supabase/supabase-js"
import { Auth0User } from "lib/createUser"
import { createContext, useContext } from "react"
import { WebGLRenderer } from "three"
import { create, useStore as useZustandStore } from "zustand"

export interface AppState {
	curHologramAngle: number
	setHologramAngle: (angle: number) => void

	isHologramUploading: boolean
	setHologramUploading: (uploading: boolean) => void

	gl: WebGLRenderer | null
	setGL: (gl: WebGLRenderer) => void

	xrSession: XRSession | null
	setXRSession: (xrSession: XRSession | null) => void

	enteringVR: boolean
	setEnteringVR: (enteringVR: boolean) => void

	didReceiveOrientationEvent: boolean
	setDidReceiveOrientationEvent: (didReceiveOrientationEvent: boolean) => void

	enableGyroPrompt: boolean
	setGyroPrompt: (status: boolean) => void

	numHolograms: number
	updateNumHolograms: (delta: number) => void

	darkMode: boolean
	setDarkMode: (enabled: boolean) => void
}

// TODO turn these into separate Store hooks?
export const useStore = create<AppState>((set) => ({
	curHologramAngle: 0,
	setHologramAngle: (angle: number) =>
		set((state: AppState) => ({
			curHologramAngle: angle,
		})),

	isHologramUploading: false,
	setHologramUploading: (isUploading: boolean) =>
		set((state: AppState) => ({
			isHologramUploading: isUploading,
		})),

	gl: null,
	setGL: (gl: WebGLRenderer) =>
		set((state: AppState) => ({
			gl: gl,
		})),

	/** The XRSession object for an active VR session, or null. */
	xrSession: null,
	setXRSession: (xrSession: XRSession | null) => set((state: AppState) => ({ xrSession })),

	/** True if we're about to enter a VR session. */
	enteringVR: false,
	setEnteringVR: (enteringVR: boolean) => set((state: AppState) => ({ enteringVR })),

	/** True if we've received deviceorientation (gyro) events from the browser */
	didReceiveOrientationEvent: false,
	setDidReceiveOrientationEvent: (didReceiveOrientationEvent: boolean) =>
		set((state: AppState) => ({ didReceiveOrientationEvent })),

	enableGyroPrompt: true,
	setGyroPrompt: (enableGyroPrompt: boolean) => set((state: AppState) => ({ enableGyroPrompt })),

	/** Number of visible <Hologram> components on the page. */
	numHolograms: 0,
	updateNumHolograms: (delta: number) =>
		set((state: AppState) => ({ numHolograms: state.numHolograms + delta })),

	darkMode: true,
	setDarkMode: (enabled: boolean) => set((state) => ({ ...state, darkMode: enabled })),
}))

export interface BlocksUserStoreProps {
	authUser?: Auth0User
	dbUser?: User
	current?: CurrentUserResult
	supabase?: SupabaseClient
	isLoading: boolean
	refetch: () => Promise<any>
}

export interface BlocksUserState extends BlocksUserStoreProps {
	setDbUser: (dbUser?: User) => void
	setCurrent: (current?: CurrentUserResult) => void
	setAuthUser: (authUser: Auth0User) => void
	setSupabase: (supabase: SupabaseClient) => void
	setIsLoading: (isLoading: boolean) => void
}

export const createBlocksUserStore = (initProps: BlocksUserStoreProps) => {
	return create<BlocksUserState>()((set, get) => ({
		...initProps,
		setDbUser: (dbUser?: User) => {
			set((state) => ({ ...state, dbUser }))
		},
		setCurrent: (current?: CurrentUserResult) => {
			set((state) => ({ ...state, current }))
		},
		setAuthUser: (authUser: Auth0User) => {
			set((state) => ({ ...state, authUser }))
		},
		setSupabase: (supabase: SupabaseClient) => {
			set((state) => ({ ...state, supabase }))
		},
		setIsLoading: (isLoading: boolean) => {
			set((state) => ({ ...state, isLoading }))
		},
	}))
}

export type BlocksUserStore = ReturnType<typeof createBlocksUserStore>
export const BlocksUserContext = createContext<BlocksUserStore | null>(null)

export function useBlocksUserStore<T>(selector: (state: BlocksUserState) => T): T {
	const store = useContext(BlocksUserContext)
	if (!store) throw new Error("Missing BlocksUserContext.Provider in the tree")
	return useZustandStore(store, selector)
}
