import { createTRPCClient, httpBatchLink } from "@trpc/client"
import SuperJSON from "superjson"
import { AppRouter } from "./routers/_app"

function getBaseUrl() {
	return process.env.BASE_URL
}

export const trpcClient = createTRPCClient<AppRouter>({
	links: [
		httpBatchLink({
			url: `${getBaseUrl()}/api/trpc`,
			transformer: SuperJSON,
		}),
	],
})

interface UserTrpcClientOptions {
	accessToken?: string
	headers?: Record<string, string>
}

export const getUserTrpcClient = ({ accessToken, headers = {} }: UserTrpcClientOptions) => {
	if (accessToken) {
		headers["Authorization"] = "Bearer " + accessToken
	}
	return createTRPCClient<AppRouter>({
		links: [
			httpBatchLink({
				url: `${getBaseUrl()}/api/trpc`,
				transformer: SuperJSON,
				headers,
			}),
		],
	})
}

export type UserTrpcClient = ReturnType<typeof getUserTrpcClient>
