// For trpc code only: This is temporary until the app is fully strictNullCheck=true

import { trpc } from "lib/trpc/trpc"
import { useEffect } from "react"
import useTriggerOnce from "./useTriggerOnce"
import { getCookie, setCookie } from "typescript-cookie"
import { useBlocksUserStore } from "./useStore"

export default function useHubspotSync() {
	const user = useBlocksUserStore((state) => state.dbUser)

	const { mutate: syncHubspot } = trpc.user.sync.useMutation()

	const { trigger } = useTriggerOnce("hubspot", () => {
		syncHubspot({ id: user?.id!, context: "useHubspotSync hook" })
	})

	useEffect(() => {
		if (user && getCookie("hubspot-sync") != "1") {
			setCookie("hubspot-sync", "1", { expires: 3600 })
			trigger()
		}
	}, [user])
}
