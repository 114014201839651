import probe from "probe-image-size"

export type ImageDetailsResponse = {
	width: number
	height: number
	/** @example `png` */
	type: string
	/** @example `image/png` */
	mime: string
	/** @example `px` */
	wUnits: string
	/** @example `px` */
	hUnits: string
	/** file size in bytes */
	length: number
	url: string
}

export async function probeImageUrl(imageUrl: string): Promise<ImageDetailsResponse | null> {
	try {
		let image = await probe(imageUrl)
		// if the image is rotated, swap the width and height
		if (image.orientation >= 5) {
			const width = image.width
			image.width = image.height
			image.height = width
		}
		return image
	} catch (error) {
		if (isHttpError(error) && error.code === 403) {
			console.warn("Forbidden access to URL:", imageUrl)
		}
		return null
	}
}

// make the error a lil bit more type safe
function isHttpError(error: any): error is { code: number } {
	return error && typeof error.code === "number"
}
