import { IncomingHttpHeaders } from "http"
import getCrawlerBot, { Bots } from "lib/getBot"
import { createContext, useContext } from "react"
import { create, useStore } from "zustand"

// Define the type for the headers
type HeadersType = IncomingHttpHeaders

// Define the Zustand store interface
interface HeaderStore {
	headers: HeadersType
	bots: Bots
}

// List of headers you want to store (filtering)
const ALLOWED_HEADERS = ["user-agent"]

// Helper function to filter headers
export const filterHeaders = (headers: HeadersType): HeadersType => {
	return Object.keys(headers)
		.filter((key) => ALLOWED_HEADERS.includes(key.toLowerCase()))
		.reduce((acc, key) => {
			acc[key] = headers[key]
			return acc
		}, {} as HeadersType)
}

// Create the Zustand store as a factory function
export const createHeaderStore = (initialValues: HeadersType = {}) =>
	create<HeaderStore>(() => ({
		headers: filterHeaders(initialValues),
		bots: getCrawlerBot(initialValues["user-agent"] ?? ""),
	}))

export const HeaderContext = createContext<ReturnType<typeof createHeaderStore> | null>(null)
export function useHeaderStore<T>(selector: (state: HeaderStore) => T): T {
	const store = useContext(HeaderContext)
	if (!store) throw new Error("Missing HeaderContext.Provider in the tree")
	return useStore(store, selector)
}
