import { Prisma } from "@prisma/client"
import type { EmbedHologram } from "./hologramProps"
import { getS3Key } from "./utils.s3"

export const getSourceImage = (hologram: Prisma.HologramGetPayload<{ include: { imageAssets: true } }>) => {
	// @ts-ignore
	const sourceImages = hologram.imageAssets ?? hologram.sourceImages
	if (!sourceImages) return null

	//first try to find one with the "SOURCE" tag
	let sourceImage = sourceImages
		.filter((image) => (image.kind === "SOURCE" || image.kind === "NONE") && !/video|gif/g.test(image.type))
		//@ts-ignore
		.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0]

	// if that fails, then try to grab it from the images.
	if (!sourceImage) {
		sourceImage = sourceImages
			.filter((image) => !image.type.includes("video") && !image.type.includes("gif"))
			// get the most recent hologram, since we can have multiple source images
			//@ts-ignore
			.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0]
	}

	return sourceImage
}

export function getSourceAssetS3Key(hologram: EmbedHologram): string | null {
	// @ts-ignore
	const sourceImage = getSourceImage(hologram)
	return sourceImage ? getS3Key(sourceImage.url) : null
}

export interface CDNOptions {
	width?: number
	height?: number
	format?: "jpeg" | "png" | "webp"
	fit?: "fill" | "cover" | "contain" | "inside" | "outside" | "scale"
	rect?: {
		x: number
		y: number
		w: number
		h: number
	}
	blur?: number
}

const getQueryParams = (options: CDNOptions) => {
	const { width, height, format = "jpeg", fit = "fill", rect, blur } = options
	let params = `?format=${format}&fit=${fit}`
	if (rect) {
		const { x, y, w, h } = rect
		params += `&rect=${x}-${y}-${w}-${h}`
	}
	if (width) {
		params += `&width=${width}`
	}
	if (height) {
		params += `&height=${height}`
	}
	if (blur) {
		params += `&blur=${blur}`
	}
	return params
}

export const getSourceCDNUrl = (hologram: EmbedHologram, options: CDNOptions) => {
	const src = getSourceAssetS3Key(hologram)
	const params = getQueryParams(options)
	return `${process.env.NEXT_PUBLIC_AWS_IMAGE_CDN}${src}${params}`
}

export const getCDNUrl = (url: string, options?: CDNOptions) => {
	const src = getS3Key(url)
	const params = options ? getQueryParams(options) : ""
	return `${process.env.NEXT_PUBLIC_AWS_IMAGE_CDN}/${src}${params}`
}
