import { GUser } from "graphql/server/types"

/** Send a custom Google Tag Manager event */
export function gtmEvent(event: string, args: any = {}) {
	if (window) {
		// @ts-ignore
		window?.dataLayer?.push({
			event,
			...args,
		})
	}
}

export function campaignGenerator(url: string, medium: string, campaign: string): string {
	return `${url}?utm_source=blocks&utm_medium=${medium}&utm_campaign=${campaign}`
}

/** Send a user specific event */
export function gtmUserEvent(user: GUser, event: string, args: any = {}) {
	gtmEvent(event, {
		"user-id": user.subId,
		...args,
	})
}
