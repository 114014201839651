import { create } from "zustand"

export type BannerState =
	| "first_time_connect"
	| "info"
	| "cant_connect"
	| "generating"
	| "no_display"
	| "disconnected"
	| "retry"
	| "connected"
	| "outdated"
	| "browser_not_supported"
	| "mobile"
	| "pending"

export const shortStates = ["connected", "pending", "retry"]

export interface BannerGlobalState {
	/** True if the user said they own a device or if they have  */
	isOwner: boolean
	setIsOwner: (state: boolean) => void

	isOpen: boolean
	setOpen: (state: boolean) => void

	state: BannerState
	setState: (state: BannerState) => void
}

export const useBannerStore = create<BannerGlobalState>((set) => ({
	isOwner: false,
	setIsOwner: (value: boolean) =>
		set((state: BannerGlobalState) => ({
			isOwner: value,
		})),

	isOpen: false,
	setOpen: (value: boolean) =>
		set((state: BannerGlobalState) => ({
			isOpen: value,
		})),

	state: "first_time_connect",
	setState: (value: BannerState) =>
		set((state: BannerGlobalState) => ({
			state: value,
		})),
}))
