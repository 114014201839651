import { useEffect, useState } from "react"

export function useColorScheme(): boolean {
	const [darkMode, setDarkMode] = useState<boolean>(false)

	function activateDarkMode(e: MediaQueryListEvent) {
		setDarkMode(e.matches)
	}

	useEffect(() => {
		// MediaQueryList
		const darkModePreference = window.matchMedia("(prefers-color-scheme: dark)")
		setDarkMode(darkModePreference.matches)

		// recommended method for newer browsers: specify event-type as first argument
		darkModePreference.addEventListener("change", activateDarkMode)

		return () => {
			darkModePreference.removeEventListener("change", activateDarkMode)
		}
	}, [])

	return darkMode
}
