import Redis from "ioredis"

export type RedisHost = "upstash" | "default"

export function getRedisUrl(host: RedisHost = "default"): string {
	if (host === "default") {
		return process.env.REDIS_URL!
	} else {
		return process.env.UPSTASH_REDIS_URL ?? process.env.REDIS_URL!
	}
}

let redisClient: Redis | null = null
export function getRedisClient(host: RedisHost = "default") {
	if (!redisClient) {
		redisClient = new Redis(getRedisUrl(host))
	}

	return redisClient
}
