import { FeatureFlagKey, Prisma, User as PrismaUser, User } from "@prisma/client"
import { captureException } from "@sentry/nextjs"
import { SupabaseClient } from "@supabase/supabase-js"
import { User as GraphqlUser } from "graphql/client/gql/types"
import { IncomingMessage, ServerResponse } from "http"
import moment from "moment"
import { SupabaseUser, getSupabase } from "../supabase/client"
import { getFlags, getHologramCount, getPicture } from "../supabase/lib/getCurrentUser"
import { getAuth0Instance } from "./auth0"
import { getCDNUrl } from "./cdn"
import { Auth0User } from "./createUser"

// 1: Define a type that includes the relation to `User`
const userWithPosts = Prisma.validator<Prisma.UserArgs>()({
	include: { avatar: true },
})

// 2: This type will include a user and all their posts
export interface UserWithAvatar
	extends Partial<Pick<Prisma.UserGetPayload<typeof userWithPosts>, "avatar">>,
		Partial<Pick<GraphqlUser, "picture">> {}

export function getUserPicture(user: UserWithAvatar): string {
	if (isPrismaUser(user)) {
		if (user.avatar) {
			const url = getCDNUrl(user.avatar.url, { width: 500, fit: "cover", height: 500 })
			return url
		}

		// Or fallback to gravatar
		// if (user.email && typeof window === "undefined")
		// return gravatarUrl(user.email, { size: 500, default: `https://blocks.glass/foil-avatar.jpg` })

		return `/foil-avatar.jpg`
	} else {
		return user.picture ?? ""
	}
}

export function isPrismaUser(user: UserWithAvatar) {
	return user.avatar !== undefined
}

export const TOC_LAST_UPDATED = new Date("2023-02-10 00:00:00")

export function hasViewedTerms(user: Partial<PrismaUser>): boolean {
	if (!user) return false
	return !!user?.lastViewedTermsAt && moment(user?.lastViewedTermsAt).isSameOrAfter(TOC_LAST_UPDATED)
}

/**
 * Get the user's permalink URL (eg. https://blocks.glass/casey)
 */
export function getUserPermalink(user: GetUserUsernameOptions) {
	return `${process.env.BASE_URL}${getUserPermalinkPath(user)}`
}

/**
 * Get the user's permalink path (eg. /casey)
 */
export function getUserPermalinkPath(user: GetUserUsernameOptions) {
	return `/${getUsernameOrUUID(user)}`
}

export interface GetUserUsernameOptions {
	id: number
	username?: string | null
	uuid: string
}

/**
 * Get the user's username (eg. casey or user123)
 */
export function getUsernameOrUUID(user: GetUserUsernameOptions): string {
	if (!user.username) {
		return user.uuid
	}
	return user.username.toLowerCase()
}

/**
 * Get the user's display name
 */
export function getUserDisplayName(user: PrismaUser) {
	if (!user.displayName) {
		// Fallback to username
		return getUsernameOrUUID(user)
	}
	return user.displayName
}

export async function getAuth0UserBySession(req: IncomingMessage, res: ServerResponse) {
	const instance = getAuth0Instance(req)
	const session = await instance.getSession(req, res)
	return session?.user ?? null
}

export interface CurrentUserState {
	totalHolograms: number
	hasSetPersona: boolean
	isLoggedIn: boolean
	flags?: FeatureFlagKey[]
	isModerator?: boolean
	hasUsername?: boolean
}

export async function getCurrentUserState(supabase: SupabaseClient, user?: User): Promise<CurrentUserState> {
	if (!user) {
		return {
			totalHolograms: 0,
			hasSetPersona: false,
			isLoggedIn: false,
		}
	}
	const flags = await getFlags(supabase, user.id)
	const has3DImageFlag = flags.includes("IMAGE_TO_HOLOGRAM")
	const isModerator = flags.includes("SHADOW_BAN")

	return {
		totalHolograms: (await getHologramCount(supabase, user.id)) ?? 0,
		hasSetPersona: user.persona !== null,
		flags,
		isLoggedIn: true,
		isModerator,
		hasUsername: !!user.username,
	}
}

export async function getSupabaseUserByReq(
	req: IncomingMessage,
	res: ServerResponse,
): Promise<SupabaseUser | null> {
	const authUser = await getAuthUser(req, res)
	if (!authUser) return null
	else return await getSupabaseUser(authUser)
}

export const getAuthUser = async (req: IncomingMessage, res: ServerResponse) => {
	const instance = getAuth0Instance(req)
	const session = await instance.getSession(req, res)
	return session ? (session.user as Auth0User) : null
}

export async function getSupabaseUser(authUser: Auth0User): Promise<SupabaseUser | null> {
	const { sub, accessToken } = authUser

	const supabase = getSupabase({ accessToken })

	let { data: dbUser, error } = await supabase.from("User").select("*").eq("subId", sub).limit(1).single()

	if (error) {
		// This is an expected error when a user is not found
		if (error.code !== "PGRST116") {
			console.error(error)
		} else {
			return null
		}
	}

	if (!dbUser) {
		const { email, nickname, picture } = authUser || {}
		// Create a user
		const { data, error } = await supabase
			.from("User")
			.insert({
				email,
				displayName: nickname,
				subId: sub,
				picture,
				updatedAt: new Date().toISOString(),
			})
			.select()
			.single()

		if (error) {
			captureException(error)
			return null
		}

		dbUser = data
	}

	dbUser.picture = await getPicture(supabase, dbUser.imageId ?? undefined)

	return { ...dbUser, uuid: "" }
}

export type DateToString<T> = {
	[K in keyof T]: T[K] extends Date ? string : T[K]
}

export function mapStringsToDates(user?: SupabaseUser | null): User | undefined {
	if (!user) {
		return undefined
	}

	const { createdAt, lastViewedTermsAt, updatedAt, ...userRest } = user
	const remapKeys = { createdAt, lastViewedTermsAt, updatedAt }

	type DateKeys = typeof remapKeys

	type KeyMap = {
		[K in keyof DateKeys]: Date
	}

	const dateVals = Object.entries(remapKeys).reduce((prev, [key, value]) => {
		const dateVal = value ? new Date(value) : undefined
		return {
			...prev,
			[key]: dateVal,
		}
	}, {} as KeyMap)

	return { ...userRest, ...dateVals }
}
