import { createHeaderStore } from "hooks/useHeaderStore"
import { IncomingHttpHeaders } from "http"
import { PropsWithChildren, useRef } from "react"
import { HeaderContext } from "hooks/useHeaderStore"

type HeaderStoreProviderArgs = {
	headers: IncomingHttpHeaders
} & PropsWithChildren

export default function HeaderStoreProvider(args: HeaderStoreProviderArgs) {
	const { headers, children } = args
	const store = useRef(createHeaderStore(headers)).current

	return <HeaderContext.Provider value={store}>{children}</HeaderContext.Provider>
}
