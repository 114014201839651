import { User } from "@prisma/client"
import { DeviceConnectionBanner } from "components/DeviceConnectionBanner"
import { Linky } from "components/Linky"
import { NavCastButton } from "components/NavCastButton"
import UploadButton from "components/UploadButtonNav"
import { useBlocksUserStore } from "hooks/useStore"
import Hologram from "stories/mocks/Hologram"
import { twMerge } from "tailwind-merge"
import DesktopNavLink from "./DesktopNavLink"
import Logo from "./Logo"
import MobileMenu from "./MobileMenu"
import MobileUserButton from "./MobileUserButton"
import { NAV_LINKS, NavigationItem, USER_NAV_LINKS } from "./navigation"
import SignUpOrLogin from "./SignUpOrLogin"
import { useRouter } from "next/router"

const filterLinksByAuth = (link: NavigationItem, user: User | undefined) => {
	if (link.hidden) return false

	if (link.authedOnly) {
		return !!user
	}

	if (link.adminOnly) {
		return !!user && user.role === "ADMIN"
	}

	return true
}

/// Flattens the 'navigation' list for the flattened mobile menu.
function flattenMenus(items: NavigationItem[]): NavigationItem[] {
	let result: NavigationItem[] = []
	for (var item of items) {
		if (item.menu) {
			result.push(...item.menu.map((i) => ({ ...i, name: `${item.name} - ${i.name}` })))
		} else {
			result.push(item)
		}
	}
	return result as NavigationItem[]
}

export default function Header(props: { className?: string }) {
	const router = useRouter()

	const forceDarkMode = router.pathname === "/"

	const user = useBlocksUserStore((store) => store.dbUser)
	const navLinks = NAV_LINKS.filter((link) => filterLinksByAuth(link, user))
	const headerClassName =
		"sticky top-0 flex w-full justify-center  py-4 backdrop-blur-md  bg-[#1F1E37] bg-opacity-30 z-10 "

	return (
		<div id="header" className={twMerge(headerClassName)}>
			<DeviceConnectionBanner />
			<div className="flex w-full max-w-screen-2xl justify-between px-4">
				<div id="left-gutter" className="flex-shrink-0">
					<MobileMenu className="sm:hidden" navItems={navLinks} />
					<Linky href="/" className="hidden flex-shrink-0 items-center hover:cursor-pointer sm:block">
						<Logo forceDarkMode={forceDarkMode} />
					</Linky>
				</div>
				<div
					id="middle-gutter"
					className="ml-6 hidden flex-shrink flex-grow items-center justify-start gap-8 overflow-auto px-4 pr-[40px] scrollbar-none sm:flex">
					{navLinks.map((link) => (
						<DesktopNavLink key={`desktop-${link.name}-link`} {...link} />
					))}
				</div>
				<div id="right-gutter" className="flex flex-shrink-0 items-center gap-4 justify-self-end">
					{/* @ts-ignore */}
					<NavCastButton hologram={Hologram} className="hidden sm:block" />
					{user ? (
						<MobileUserButton desktop={true} items={USER_NAV_LINKS} className="hidden w-8 min-w-8 sm:block" />
					) : (
						<SignUpOrLogin className="hidden sm:flex" />
					)}
					<UploadButton />
				</div>
			</div>
		</div>
	)
}
