import { QueueInfo } from "../globals"
import { Hologram, PrismaClient } from "@prisma/client"
import createQueue from "../../queues/createQueue"

interface CreateAnimatedPreviewsJob {
	hologramId: number
}

type JobArgs = {
	hologramId?: number
	hologram?: Hologram | null
	prisma: PrismaClient
}

export async function ensureCreateAnimatedPreviewsJob(args: JobArgs) {
	//
	// TODO: when our job queues get very big, this will probably not scale.
	//
	// const jobsWaiting = (await CreateAnimatedPreviews.queue.getWaiting()).filter(
	// 	(job) => job.data.hologramId === hologramId
	// )
	// if (jobsWaiting.length > 0) {
	// 	console.log(`there are already jobs waiting for hologram ${hologramId}`)
	// 	return
	// }

	if (!args.hologram) {
		args.hologram = await args.prisma.hologram.findUnique({
			where: {
				id: args.hologramId,
			},
		})
	}

	if (!args.hologram) {
		throw new Error(`hologram not found for id ${args.hologramId}`)
	}

	if (args.hologram.type == "RGBD") {
		console.log("skipping animated preview creation for rgbd hologram")
		return
	}

	try {
		// console.log(`no waiting jobs, starting one for hologram ${hologramId}`)
		return await CreateAnimatedPreviews.queue.add(
			{ hologramId: args.hologram?.id },
			{
				priority: 4,
			},
		)
	} catch (e) {
		console.error(e)
	}
}

/** Create GIFs or videos from holograms. See the `blocks-animator` project for the processing implementation of this queue. */
export const CreateAnimatedPreviews: QueueInfo<CreateAnimatedPreviewsJob> = {
	queue: createQueue<CreateAnimatedPreviewsJob>("createAnimatedPreviews", {
		redisHost: "default",
		defaultJobOptions: {
			removeOnComplete: {
				age: 1000 * 60 * 60 * 24, // 24 hours
			},
		},
	}),
	/*
    process: undefined // See the `blocks-animator` Github repo
    */
}
