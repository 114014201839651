import { User } from "@prisma/client"
import HelpButton from "./HelpButton"
import SignUpOrLogin from "./SignUpOrLogin"
import { useBlocksUserStore } from "hooks/useStore"
import { Linky } from "components/Linky"
import { twMerge } from "tailwind-merge"

interface MobileMenuFooterProps {
	user?: User
	className?: string
}
export default function MobileMenuFooter(props: MobileMenuFooterProps) {
	const user = useBlocksUserStore((store) => store.dbUser)
	return (
		<div
			className={twMerge(
				"flex w-full justify-between pb-2",
				user ? "items-end" : "items-center",
				props.className,
			)}>
			{user ? (
				<div className="flex flex-col gap-2">
					<Linky
						href="/settings"
						className="text-white hover:text-light-purple hover:underline active:text-light-purple active:underline">
						Settings
					</Linky>
					<Linky
						href="/api/auth/logout"
						className="text-white hover:text-light-purple hover:underline active:text-light-purple active:underline">
						Log out
					</Linky>
				</div>
			) : (
				<SignUpOrLogin />
			)}
			<HelpButton />
		</div>
	)
}
