import { getS3Client } from "../../lib/s3"
import { timeout } from "../../lib/utils"
import { cloudfrontInvalidate } from "../../lib/utils.aws"
import createQueue from "../../queues/createQueue"
import { QueueInfo } from "../globals"
import { DeleteObjectCommand } from "@aws-sdk/client-s3"

/** Deletes one or more files from S3. Pass in a list of S3 URLs or just the keys. */
export const DeleteFromS3: QueueInfo<string[]> = {
	queue: createQueue<string[]>("deleteFromS3", {
		redisHost: "default",
		limiter: {
			duration: 2000,
			max: 1,
		},
	}),
	process: async (job, done) => {
		try {
			await deleteObjects(job.data)
			await job.log("Deleted files off S3")

			for await (const url of job.data) {
				const key = url.replace(`https://s3.amazonaws.com/${process.env.NEXT_AWS_S3_BUCKET_NAME}/`, "")
				await timeout(1000)
				await cloudfrontInvalidate(key)
				await job.log(`Invalidated ${key}`)
			}

			done()
		} catch (err) {
			console.error(err)
			done(err as Error)
		}
	},
}

async function deleteObjects(urls: string[]) {
	return new Promise(async (resolve, reject) => {
		// Load only when called to prevent bundling of S3 SDK on the client side

		// Dont delete keys that are in the testing folder (filter them out).
		// we use these for seeding.
		urls = urls.filter((url) => !url.includes("/testing/"))

		if (urls.length === 0) {
			resolve(true)
			return
		}

		const s3 = await getS3Client()

		const commands = urls.map((url) => {
			return new DeleteObjectCommand({
				Bucket: process.env.NEXT_AWS_S3_BUCKET_NAME ?? "",
				Key: url.replace(`https://s3.amazonaws.com/${process.env.NEXT_AWS_S3_BUCKET_NAME}/`, ""),
			})
		})

		try {
			await Promise.all(commands.map((command) => s3.send(command)))
		} catch (err) {
			console.error("Job failed", err)
			reject(err)
		}

		return true
	})
}
