import { IconArgs } from "./FacebookIcon"

export default function DiscordIcon(args: IconArgs) {
	return (
		<svg
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 22 24"
			xmlSpace="preserve"
			className={args.className}>
			<g>
				<path
					className="st0"
					d="M10,11.4c-0.4,0-0.7,0.5-0.7,1c0,0.6,0.3,1,0.7,1c0.4,0,0.7-0.5,0.7-1C10.7,11.9,10.4,11.4,10,11.4z"
				/>
				<path
					className="st0"
					d="M18.5,6.2c0-1.1-0.9-2-1.9-2H6C4.9,4.2,4,5.1,4,6.2v10.3c0,1.1,0.9,2,1.9,2h9.4l-0.4-1.2l3.5,2.8V6.2z    M13.1,15.5L12.8,15c0.6-0.2,1.2-0.6,1.8-1.3c-0.7,0.6-1.7,1-3.3,1c-1.6,0-2.7-0.5-3.3-1c0.6,0.7,1.2,1.1,1.8,1.3l-0.3,0.5   c0,0-1.6,0-2.7-1.4c0.2-3.3,1.3-5,1.3-5S8.8,8.3,10,8.2l0.1,0.2C9.2,8.6,8.6,9,8.1,9.5c0.8-0.5,1.7-1,3.1-1c1.5,0,2.3,0.5,3.1,1   c-0.5-0.5-1-0.9-2-1.1l0.1-0.2c1.1,0.1,2.1,0.9,2.1,0.9s1.1,1.7,1.3,5C14.8,15.4,13.1,15.5,13.1,15.5z"
				/>
				<path
					className="st0"
					d="M12.5,11.4c-0.4,0-0.7,0.5-0.7,1c0,0.6,0.3,1,0.7,1c0.4,0,0.7-0.5,0.7-1C13.3,11.9,12.9,11.4,12.5,11.4z"
				/>
			</g>
		</svg>
	)
}
