import { SoftwareType, User } from "@prisma/client"
import { HAS_SEEN_ONBOARDING_COOKIE } from "lib/cookies"
import { getCookie } from "typescript-cookie"
import { create } from "zustand"

export type OnboardingStep =
	| "welcome"
	| "persona"
	| "select_upload_type"
	| "quilt"
	| "select_software"
	| "download_software"
	| "upload_quilt"
	| "upload_image"
	| "coming_soon"
	| "username"

type OnboardingStore = {
	currentStep?: OnboardingStep
	setCurrentStep: (step?: OnboardingStep) => void
	software?: SoftwareType
	setSoftware: (software: SoftwareType) => void
	getResumeStep: (user: Partial<User>) => OnboardingStep | null
}

const useOnboardingStore = create<OnboardingStore>((set, get) => ({
	currentStep: undefined,
	setCurrentStep: (step) => set({ currentStep: step }),
	setSoftware: (software) => set({ software }),
	getResumeStep: (user: Partial<User>) => {
		const hasSeenWelcome = getCookie(HAS_SEEN_ONBOARDING_COOKIE)
		if (hasSeenWelcome) {
			return null
		}
		if (!user.persona) {
			return "welcome"
		}
		if (!user.username) {
			return "username"
		}

		return null
	},
}))

export default useOnboardingStore
