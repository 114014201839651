import { Point } from "@influxdata/influxdb-client"
import { influxDb } from "./InfluxDB"

const queryClient = influxDb.getQueryApi(process.env.INFLUXDB_TOKEN!)

export type INFLUX_MEASUREMENTS =
	| "hologramLoadTime"
	| "hologramPageView"
	| "liteformsUsage"
	| "liteformsCosts"

export const MEASUREMENT_HOLOGRAM_LOAD_TIME = "hologramLoadTime"
export const MEASUREMENT_HOLOGRAM_PAGE_VIEW = "hologramPageView"

const writeClient = influxDb.getWriteApi(process.env.INFLUXDB_ORG!, process.env.INFLUXDB_BUCKET!, "ns", {
	maxRetries: 0,
	writeFailed: (error, lines, attempts) => {
		// console.log("Write failed", error)
	},
})

const goInfluxClient = influxDb.getWriteApi(
	process.env.INFLUXDB_ORG!,
	process.env.INFLUXDB_BUCKET_GO!,
	"ns",
	{
		maxRetries: 0,
		writeFailed: (error, lines, attempts) => {
			// console.log("Write failed", error)
		},
	},
)

export interface InfluxDBWriter {
	measurement: INFLUX_MEASUREMENTS
	/**
	 * @example
	 * ```ts
	 * new Date().toISOString()
	 * ```
	 */
	timestamp: string

	/**
	 * @example
	 * ```ts
	 * let tags: Record<string, string> = {
	 *		tag_key: "low cardinality value (e.g., some event type)",
	 * }
	 * ```
	 */
	tags?: Record<string, string>

	/**
	 * @example
	 * ```ts
	 * let fields: Record<string, string | number> = {
	 *		field_key: "high cardinality value (e.g., some UUID)",
	 * }
	 * ```
	 *
	 * A measurement point must have at least one field
	 */
	fields: Record<string, string | number>
}

export async function influxWrite(data: InfluxDBWriter) {
	const point = new Point(data.measurement).timestamp(new Date(data.timestamp))

	if (data.tags) {
		const tags = data.tags
		for (const [key, value] of Object.entries(tags)) {
			point.tag(key, value)
		}
	}

	const fields = data.fields
	for (const [key, value] of Object.entries(fields)) {
		if (typeof value === "number" && Number.isInteger(value)) {
			point.intField(key, value)
		} else if (typeof value === "number" && !Number.isInteger(value)) {
			point.floatField(key, value)
		} else if (typeof value === "boolean") {
			point.booleanField(key, value)
		} else {
			point.stringField(key, value)
		}
	}

	await writePoint(point)
}

export async function writePoint(point: Point) {
	const writtenPoint = writeClient.writePoint(point)
	await writeClient.flush()
	return writtenPoint
}

export function getHologramTotalViews(id: number, lastXDays: number = 99999): Promise<number> {
	const fluxQuery = `from(bucket: "${process.env.INFLUXDB_BUCKET}")
 |> range(start: -${lastXDays}d)
 |> filter(fn: (r) => r._measurement == "${MEASUREMENT_HOLOGRAM_PAGE_VIEW}")
 |> filter(fn: (r) => r._field == "hologramId" and r._value == "${id}")
 |> count()`

	return new Promise<number>((resolve, reject) => {
		queryClient.queryRows(fluxQuery, {
			next: (row, tableMeta) => {
				const tableObject = tableMeta.toObject(row)
				// console.log(tableObject)
				resolve(tableObject._value)
			},
			error: (error) => {
				console.error("\nError", error)
				reject(error)
			},
			complete: () => {},
		})
	})
}

/**
 * Get total aggregate views across all holograms
 * @param lastXDays - How many days to fetch (default: 99999)
 */
export function getTotalViewsByHologram(lastXDays: number = 99999): Promise<Record<number, number>> {
	const fluxQuery = `from(bucket: "${process.env.INFLUXDB_BUCKET}")
 |> range(start: -${lastXDays}d)
 |> filter(fn: (r) => r._measurement == "${MEASUREMENT_HOLOGRAM_PAGE_VIEW}")
 |> filter(fn: (r) => r._field == "hologramId" or r._field == "view")
 |> pivot(rowKey:["_time"], columnKey: ["_field"], valueColumn: "_value")
 |> group(columns: ["hologramId"])
 |> count(column: "view")`

	return new Promise((resolve, reject) => {
		let data = {}
		queryClient.queryRows(fluxQuery, {
			next: (row, tableMeta) => {
				const tableObject = tableMeta.toObject(row)
				data[tableObject.hologramId] = tableObject.view
			},
			error: (error) => {
				console.error("\nError", error)
				reject(error)
			},
			complete: () => {
				resolve(data)
			},
		})
	})
}

type Tag = {
	key: string
	value: string
}

interface syncItemTrackingArgs {
	event: "sync_item_start" | "sync_item_syncing" | "sync_item_success" | "playlist_sync_success"
	tags: {
		firmware: string
	}
	fields: {
		hologramId?: string
		playlistId: string
		deviceSerial: string
		syncItemId?: string
		userId: string
		requestId: string
	}
}

export async function syncItemTracking(args: syncItemTrackingArgs) {
	const { event, tags, fields } = args

	const timeStamp = new Date().toISOString()
	const point = new Point("go_ux_metrics").timestamp(new Date(timeStamp))
	point.tag("event", event)

	for (const [key, value] of Object.entries(tags)) {
		point.tag(key, value)
	}

	for (const [key, value] of Object.entries(fields)) {
		if (typeof value === "number" && Number.isInteger(value)) {
			point.intField(key, value)
		} else if (typeof value === "number" && !Number.isInteger(value)) {
			point.floatField(key, value)
		} else if (typeof value === "boolean") {
			point.booleanField(key, value)
		} else {
			point.stringField(key, value)
		}
	}

	try {
		goInfluxClient.writePoint(point)
		await goInfluxClient.flush()
	} catch (e) {
		console.error("failed to contact influx", e)
	}

	return
}
