import { cloneDeep } from "@apollo/client/utilities"
import { Privacy } from "@prisma/client"
import { ParsedUrlQuery } from "querystring"
import { PrivacyType } from "../graphql/client/gql/types"
import { HOLOGRAM_DEFAULT_ASPECT_RATIO } from "../prisma/models/Holograms.model"
import { HologramPermalinkOptions, getPermalinkUrl } from "./utils.hologram"
import { GetUserUsernameOptions } from "./utils.user"

export type OembedArgs = {
	url?: string
	method?: "json" | "xml"
	maxwidth?: number
	maxheight?: number
	aspect?: number
}

export const DEFAULT_OEMBED_MAXWIDTH = 1000
export type EmbedTheme = "auto" | "dark" | "light" | "none"

export interface EmbedProps {
	dropshadow: boolean
	/**
	 * Do the bootup wiggle animation
	 * @default true
	 */
	wiggle: boolean
	/**
	 * Constant animation until user interacts with hologram. If this is true, it cancels out wiggle animation.
	 * @default false
	 **/
	animate: boolean
	/** @see {@link EmbedHologramProps.frameTilt} */
	frameTilt: boolean
	frametilt: boolean
	gyro: boolean
	permalink: boolean
	blend: boolean
	bg: string | null
	theme: EmbedTheme
	aspect: number
}

export const embedPropDefaults: EmbedProps = {
	dropshadow: true,
	wiggle: true,
	animate: false,
	frameTilt: true,
	frametilt: true,
	gyro: false,
	permalink: true,
	blend: true,
	bg: "",
	theme: "none",
	aspect: 0, // 0 == undefined
}

export function normalizeEmbedProps(input: ParsedUrlQuery): EmbedProps {
	let props: EmbedProps = cloneDeep(embedPropDefaults)

	for (const prop in input) {
		if (props[prop] != undefined) {
			if (typeof embedPropDefaults[prop] == "boolean") {
				props[prop] = input[prop] == "1" || input[prop] == "true"
			} else if (typeof embedPropDefaults[prop] == "string") {
				props[prop] = input[prop]
			} else if (typeof embedPropDefaults[prop] == "number") {
				props[prop] = parseFloat(input[prop] as string)
			}
		}
	}

	// BG post processing
	if (!props.bg?.match(/[a-z0-6]{6}/)) {
		props.bg = null
	}

	props.frameTilt = props.frameTilt || props.frametilt

	return props
}

export interface EmbedUrlHologramProps {
	id: number
	uuid?: string | null
	privacy: PrivacyType | Privacy
}
export function getEmbedUrl(hologram: EmbedUrlHologramProps, inputProps?: Partial<EmbedProps> | null) {
	let embedProps = getChangedEmbedProps(inputProps)

	// aspect not used in embed url
	delete embedProps["aspect"]

	let querystring = toQueryString(embedProps)

	if (querystring) querystring = "?" + querystring

	if (hologram.privacy && hologram.privacy == "UNLISTED") {
		return `${process.env.BASE_URL}/embed/${hologram.uuid}${querystring}`
	}
	return `${process.env.BASE_URL}/embed/${hologram.id}${querystring}`
}

export interface EmbedCodeHologramProps extends EmbedUrlHologramProps {
	aspectRatio?: number | null
}
export interface EmbedCodeProps {
	width?: number
	height?: number
	props?: Partial<EmbedProps>
}

export function getEmbedCode(hologram: EmbedCodeHologramProps, args: EmbedCodeProps = {}) {
	let size = ""
	let responsive = false
	if (args.width && args.height) {
		size = `width="${args.width}" height="${args.height}"`
	} else {
		responsive = true
		size = `style="position:absolute;top:0;left:0;width:100%;height:100%;"`
	}

	const url = getEmbedUrl(hologram, args.props)
	let iframe: string = `<iframe src="${url}"
		frameborder="0"
		${size}
		allow="autoplay; encrypted-media; xr-spatial-tracking; accelerometer; gyroscope; magnetometer"
		allowfullscreen
		mozallowfullscreen="true"
		webkitallowfullscreen="true"
		execution-while-out-of-viewport="true"
		execution-while-not-rendered="true"></iframe>`

	// strip newlines & double spaces
	iframe = iframe?.replace(/\n|\t/g, " ")
	iframe = iframe?.replace(/\s{2,}/g, " ")

	const classname = "lkg-blocks-player"

	if (responsive) {
		const aspectRatio = hologram.aspectRatio || HOLOGRAM_DEFAULT_ASPECT_RATIO
		const width = 1280
		const height = 1280 / aspectRatio
		const precision = 1000
		const paddingBottom = Math.round((height / width) * 100 * precision) / precision
		return `<div class="${classname}" style="padding:${paddingBottom}% 0 0 0;position:relative;">${iframe}</div>`
	}

	return `<div class="${classname}">${iframe}</div>`
}

interface OembedProps {
	user: GetUserUsernameOptions
	hologram: HologramPermalinkOptions
	embedProps?: EmbedProps
	maxHeight?: number
}

export function getOEmbedUrl(props: OembedProps) {
	const embedPropsDiff = getChangedEmbedProps(props.embedProps)
	const permalinkUrl = getPermalinkUrl(props.user, props.hologram, embedPropsDiff)

	let oembedArgs: OembedArgs = {
		url: permalinkUrl,
		method: "json",
	}

	if (props.maxHeight) oembedArgs.maxheight = props.maxHeight

	const querystring = toQueryString(oembedArgs)

	return `${process.env.BASE_URL}/api/oembed?${querystring}`
}

export function getChangedEmbedProps(inputProps?: Partial<EmbedProps> | null): Partial<EmbedProps> {
	let props: Partial<EmbedProps> = {}
	// Only include any params that are different than the default
	for (const key in inputProps) {
		if (inputProps[key] && embedPropDefaults[key] != inputProps[key]) {
			props[key] = inputProps[key]
		}
	}
	return props
}

export function toQueryString(obj: object) {
	return Object.keys(obj || {})
		.map((key) => key + "=" + encodeURIComponent(obj[key]))
		.join("&")
}
