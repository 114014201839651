import { create } from "zustand"

interface AppState {
	trigger: { [key: string]: boolean }
	setTrigger: (key: string, value: boolean) => void
}

const useTriggerStore = create<AppState>((set) => ({
	trigger: {},
	setTrigger: (key: string, value: boolean) =>
		set((state) => ({
			trigger: { ...state.trigger, [key]: value },
		})),
}))

/**
 * Use this hook to trigger a callback once per page load. It will not be
 * called again until the page is fully refreshed.
 */
export default function useTriggerOnce(value: string, callback: () => any) {
	const { trigger, setTrigger } = useTriggerStore((state: AppState) => state)

	function onTrigger() {
		if (!trigger[value]) {
			setTrigger(value, true)
			callback()
		}
	}

	return {
		trigger: onTrigger,
	}
}
