import { SpinnerCircular } from "spinners-react"

export default function SpinnerLoader({
	className = "",
	color = "#a5f",
	secondaryColor = "rgba(0, 0, 0, 0.1)",
	speed = 100,
	thickness = 200,
}) {
	return (
		<SpinnerCircular
			thickness={thickness}
			color={color}
			secondaryColor={secondaryColor}
			className={className}
			speed={speed}
		/>
	)
}
