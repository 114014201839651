import { QuestionMarkIcon } from "components/icons/QuestionMarkIcon"
import { twMerge } from "tailwind-merge"

export default function HelpButton() {
	return (
		<div
			onClick={() => {
				if ("Beacon" in window && typeof window.Beacon === "function") {
					// @ts-ignore
					window.Beacon("open")
				}
			}}
			style={{
				boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
			}}
			className={twMerge(
				"flex cursor-pointer items-center justify-center rounded-full bg-white bg-opacity-20 p-2",
				"transform transition-transform duration-200 hover:-translate-y-1 hover:drop-shadow-md active:-translate-y-1 active:drop-shadow-md",
				"aspect-1",
			)}>
			<QuestionMarkIcon />
		</div>
	)
}
