// your-tooltip.jsx
import React from "react"
import * as RadixTooltip from "@radix-ui/react-tooltip"
import styles from "./Tooltip.module.css"
import { classNames } from "lib/classNames"

type TooltipArgs = Omit<RadixTooltip.TooltipContentProps, "content"> & {
	children: any
	content: any
	open?: boolean
	defaultOpen?: boolean
	disableHoverableContent?: boolean
	onOpenChange?: (open: boolean) => void
	delayDuration?: number
	side?: string
}

export function Tooltip({
	children,
	content,
	open,
	defaultOpen,
	onOpenChange,
	delayDuration,
	disableHoverableContent,
	...props
}: TooltipArgs) {
	return (
		<RadixTooltip.Root
			open={open}
			defaultOpen={defaultOpen}
			// onOpenChange={onOpenChange}
			delayDuration={delayDuration ? delayDuration : 700}
			disableHoverableContent={disableHoverableContent == undefined ? true : disableHoverableContent}>
			<RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
			<RadixTooltip.Portal>
				<RadixTooltip.Content
					side={props.side || "top"}
					align="center"
					{...props}
					className={classNames(styles.TooltipContent, props.className)}
					style={{ zIndex: "1000" }}>
					<div
						tabIndex={-1}
						className="rounded-lg border border-[#ffffff10] bg-white p-2 text-xs dark:bg-dark-purple-100 dark:text-white">
						{content}
					</div>
					<div className="h-2"></div>
					{/* <TooltipPrimitive.Arrow width={11} height={5} color="1f1e37" className="bg-dark-purple-100" /> */}
				</RadixTooltip.Content>
			</RadixTooltip.Portal>
		</RadixTooltip.Root>
	)
}
