import { Hologram, ImageAsset, PrismaClient } from "@prisma/client"
import { getCDNUrl } from "../../lib/cdn"
import { DeleteFromS3 } from "../../queues/jobs/deleteFromS3"

export function ImageAssets(prisma: PrismaClient) {
	return Object.assign(prisma, {
		async previewGifAssets(hologram: Partial<Hologram>): Promise<ImageAsset[] | undefined> {
			const gifs = await prisma.hologram
				.findUnique({
					where: {
						id: hologram.id,
					},
				})
				.imageAssets({
					where: { type: "image/gif" },
					orderBy: [{ width: "asc" }, { id: "desc" }],
					distinct: ["width"],
				})

			return gifs?.map((gif) => {
				return {
					...gif,
					url: getCDNUrl(gif.url),
				}
			})
		},

		async previewVideoAssets(hologram: Partial<Hologram>): Promise<ImageAsset[] | undefined> {
			const videos = await prisma.hologram
				.findUnique({
					where: {
						id: hologram.id,
					},
				})
				.imageAssets({
					where: { type: "video/mp4" },
					orderBy: [{ width: "asc" }, { id: "desc" }],
					distinct: ["width"],
				})

			return videos?.map((video) => {
				return {
					...video,
					url: video.url,
				}
			})
		},

		async delete(id: number): Promise<boolean> {
			if (id > 0) {
				const imageAsset = await prisma.imageAsset.findFirst({ where: { id } })

				if (imageAsset) {
					DeleteFromS3.queue.add([imageAsset.url])
					await prisma.imageAsset.delete({ where: { id } })
					return true
				}
			}

			return false
		},
	})
}
