import React from "react"
const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className={props.className}
		data-name="Layer 2"
		viewBox="0 0 33.33 33.33">
		<path
			d="M16.67 14.83a1.83 1.83 0 1 0 .002 3.658 1.83 1.83 0 0 0-.002-3.658Zm0-14.83C7.47 0 0 7.47 0 16.67s7.47 16.67 16.67 16.67 16.67-7.47 16.67-16.67S25.87 0 16.67 0Zm3.96 19.65c-.2.43-.54.77-.97.97L7.91 26.08c-.42.2-.86-.24-.66-.66l5.46-11.75c.2-.43.54-.77.97-.97l11.75-5.46c.42-.2.86.24.66.66l-5.46 11.75Z"
			data-name="Layer 1"
			style={{
				fill: "#fff",
				strokeWidth: 0,
			}}
		/>
	</svg>
)
export default SvgComponent
