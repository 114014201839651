import { Disclosure, Transition } from "@headlessui/react"
import { NavigationItem } from "./navigation"
import { ChevronDownIcon } from "@heroicons/react/24/solid"
import { twMerge } from "tailwind-merge"
import { Linky } from "components/Linky"

interface MobileSubMenuProps {
	navItems: NavigationItem[]
	className?: string
	name: string
}

export default function MobileSubMenu(props: MobileSubMenuProps) {
	const { navItems, name } = props

	return (
		<Disclosure as="div" className="w-full">
			{({ open }) => {
				return (
					<div
						className={twMerge(
							"overflow-hidden rounded-md bg-white",
							open ? "bg-opacity-10" : "bg-opacity-0",
						)}>
						<Disclosure.Button
							className={twMerge(
								"flex w-full items-center justify-between  px-3 py-2 text-left font-medium outline-none transition-all duration-200",
								"bg-white bg-opacity-0  text-white  hover:bg-opacity-10 active:bg-opacity-10",
							)}>
							{name}
							<ChevronDownIcon className="right-0 h-4 w-4 " />
						</Disclosure.Button>
						<Transition
							enter="transition-all duration-300 ease-in-out overflow-hidden"
							enterFrom="max-h-0"
							enterTo="max-h-60 opacity-1"
							leave="transition-all duration-300 ease-in-out"
							leaveFrom="max-h-60 opacity-1"
							leaveTo="max-h-0">
							<Disclosure.Panel className={"flex flex-col overflow-hidden rounded-bl-md rounded-br-md"}>
								{navItems.map((item) => {
									return (
										<Linky
											href={item.href}
											className="w-full bg-white bg-opacity-0 px-3 py-2 text-sm font-medium tracking-tight text-white hover:bg-opacity-20 active:bg-opacity-20"
											key={`mobile-${item.name}-btn`}>
											{item.name}
										</Linky>
									)
								})}
							</Disclosure.Panel>
						</Transition>
					</div>
				)
			}}
		</Disclosure>
	)
}
