import Redis, { RedisOptions } from "ioredis"
import Queue, { QueueOptions } from "bull"
import EventEmitter from "events"
import { IS_LOCAL_DEV, IS_PRODUCTION } from "../lib/environment"
import { RedisHost, getRedisUrl } from "../lib/redisClient"

/** Shared clients */
let clients: Record<string, Redis> = {}
let subscribers: Record<string, Redis> = {}

interface QueueArgs extends QueueOptions {
	/**
	 * @default `upstash`
	 */
	redisHost?: RedisHost
}

try {
	EventEmitter.defaultMaxListeners = 50
} catch (e) {}

// slow down polling in production & staging
let guardInterval: number | undefined = IS_PRODUCTION ? 10000 : 60000
if (IS_LOCAL_DEV) {
	guardInterval = undefined
}

function createQueue<T>(queueName: string, args?: QueueArgs) {
	let redisUrl = getRedisUrl(args?.redisHost)

	return new Queue<T>(queueName, {
		...args,
		settings: {
			guardInterval,
			...args?.settings,
		},
		defaultJobOptions: {
			attempts: 3,
			removeOnComplete: {
				age: 60 * 60 * 24 * 30, // 30-day retention
				count: 500, // Keep the last 500 jobs
			},
			...args?.defaultJobOptions,
		},
		createClient(type, redisOpts) {
			const opts: RedisOptions = {
				maxRetriesPerRequest: null,
				enableReadyCheck: false,
				...redisOpts,
			}

			switch (type) {
				case "client":
					if (!clients[redisUrl]) {
						// console.log("Creating Redis client for", redisUrl)
						clients[redisUrl] = new Redis(redisUrl, opts)
					}
					return clients[redisUrl]
				case "subscriber":
					if (!subscribers[redisUrl]) {
						// console.log("Creating Redis subscriber for", redisUrl)
						subscribers[redisUrl] = new Redis(redisUrl, opts)
					}
					return subscribers[redisUrl]
				case "bclient":
					return new Redis(redisUrl, opts)
				default:
					throw new Error("Unexpected connection type: ", type)
			}
		},
	})
}

export default createQueue
