const Hologram = {
	__typename: "Hologram",
	id: 1059,
	type: "QUILT",
	uuid: null,
	title: "Jetpack Cat 2, model by Duffator on Sketchfab",
	createdAt: "2022-07-03T00:38:42.353Z",
	privacy: "PUBLIC",
	aspectRatio: 0.75,
	quiltTileCount: 45,
	quiltCols: 5,
	quiltRows: 9,
	rgbdDepthiness: 0.5,
	rgbdFocus: 0,
	rgbdZoom: 0.6,
	totalViews: 114,
	permalink: "https://blocks.glass/arturojreal/1059",
	user: {
		__typename: "User",
		id: 32,
		username: "arturojreal",
		displayName: "Arturo J. Real",
		picture:
			"https://dl.blocks.glass/u/eba9adbbff824c7f/ajr.jpg?ixlib=js-3.7.0&fm=webp&auto=format&fit=crop&crop=faces&w=500&h=500",
	},
	thumbnail: {
		__typename: "ImageAsset",
		url: "https://dl.blocks.glass/u/c0818f07f5af465a/jetpack_cat2_qs5x9a0.75.jpg?ixlib=js-3.7.0&fm=jpg&fit=scale&rect=4914%2C3640%2C1638%2C910&h=1334&w=1000&fit=scale",
		width: 1000,
		height: 1334,
		type: "image/jpg",
	},
	sourceImages: [
		{
			__typename: "ImageAsset",
			height: 8190,
			width: 8190,
			url: "https://dl.blocks.glass/u/c0818f07f5af465a/jetpack_cat2_qs5x9a0.75.jpg",
			type: "image/jpeg",
			kind: "SOURCE",
		},
	],
	previewGifAssets: [
		{
			__typename: "ImageAsset",
			url: "https://dl.blocks.glass/u/369ea177a2dd4d55/1059-jetpack_cat2modelbyduffatoronsketchfab-250.gif",
			width: 250,
			height: 334,
			type: "image/gif",
		},
		{
			__typename: "ImageAsset",
			url: "https://dl.blocks.glass/u/31f78bf2e3fd4206/1059-jetpack_cat2modelbyduffatoronsketchfab-350.gif",
			width: 350,
			height: 468,
			type: "image/gif",
		},
	],
	previewVideoAssets: [
		{
			__typename: "ImageAsset",
			url: "https://dl.blocks.glass/u/5234fea19652432e/1059-jetpack_cat2modelbyduffatoronsketchfab-500.mp4",
			width: 500,
			height: 668,
			type: "video/mp4",
		},
	],
	sourceImage: {
		__typename: "ImageAsset",
		height: 8190,
		width: 8190,
		url: "https://dl.blocks.glass/u/c0818f07f5af465a/jetpack_cat2_qs5x9a0.75.jpg",
		type: "image/jpeg",
	},
	hiddenPermalinkPath: "/arturojreal/null",
	embedUrl: "https://blocks.glass/embed/1059",
	embedCode:
		'<div class="lkg-blocks-player" style="padding:133.333% 0 0 0;position:relative;"><iframe src="https://blocks.glass/embed/1059" frameborder="0" style="position:absolute;top:0;left:0;width:100%;height:100%;" allow="autoplay; encrypted-media; xr-spatial-tracking; accelerometer; gyroscope; magnetometer" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" execution-while-out-of-viewport="true" execution-while-not-rendered="true"></iframe></div>',
	permalinkPath: "/arturojreal/1059",
	permalinkUrl: "https://blocks.glass/arturojreal/1059",
	editLinkPath: "/arturojreal/1059/edit",
	aspectRatioClassName: "aspect-h-4 aspect-w-3",
	quilts: [
		{
			__typename: "ImageAsset",
			height: 1000,
			width: 1000,
			url: "https://dl.blocks.glass/u/c0818f07f5af465a/jetpack_cat2_qs5x9a0.75.jpg",
			type: "image/webp",
		},
		{
			__typename: "ImageAsset",
			height: 1500,
			width: 1500,
			url: "https://dl.blocks.glass/u/c0818f07f5af465a/jetpack_cat2_qs5x9a0.75.jpg",
			type: "image/webp",
		},
		{
			__typename: "ImageAsset",
			height: 2000,
			width: 2000,
			url: "https://dl.blocks.glass/u/c0818f07f5af465a/jetpack_cat2_qs5x9a0.75.jpg",
			type: "image/webp",
		},
		{
			__typename: "ImageAsset",
			height: 2500,
			width: 2500,
			url: "https://dl.blocks.glass/u/c0818f07f5af465a/jetpack_cat2_qs5x9a0.75.jpg",
			type: "image/webp",
		},
		{
			__typename: "ImageAsset",
			height: 3000,
			width: 3000,
			url: "https://dl.blocks.glass/u/c0818f07f5af465a/jetpack_cat2_qs5x9a0.75.jpg",
			type: "image/webp",
		},
		{
			__typename: "ImageAsset",
			height: 3500,
			width: 3500,
			url: "https://dl.blocks.glass/u/c0818f07f5af465a/jetpack_cat2_qs5x9a0.75.jpg",
			type: "image/webp",
		},
		{
			__typename: "ImageAsset",
			height: 4000,
			width: 4000,
			url: "https://dl.blocks.glass/u/c0818f07f5af465a/jetpack_cat2_qs5x9a0.75.jpg",
			type: "image/webp",
		},
		{
			__typename: "ImageAsset",
			height: 4500,
			width: 4500,
			url: "https://dl.blocks.glass/u/c0818f07f5af465a/jetpack_cat2_qs5x9a0.75.jpg",
			type: "image/webp",
		},
		{
			__typename: "ImageAsset",
			height: 5000,
			width: 5000,
			url: "https://dl.blocks.glass/u/c0818f07f5af465a/jetpack_cat2_qs5x9a0.75.jpg",
			type: "image/webp",
		},
		{
			__typename: "ImageAsset",
			height: 5500,
			width: 5500,
			url: "https://dl.blocks.glass/u/c0818f07f5af465a/jetpack_cat2_qs5x9a0.75.jpg",
			type: "image/webp",
		},
		{
			__typename: "ImageAsset",
			height: 6000,
			width: 6000,
			url: "https://dl.blocks.glass/u/c0818f07f5af465a/jetpack_cat2_qs5x9a0.75.jpg",
			type: "image/webp",
		},
		{
			__typename: "ImageAsset",
			height: 6500,
			width: 6500,
			url: "https://dl.blocks.glass/u/c0818f07f5af465a/jetpack_cat2_qs5x9a0.75.jpg?ixlib=js-3.7.0&fm=webp&fit=max&w=6500",
			type: "image/webp",
		},
		{
			__typename: "ImageAsset",
			height: 7000,
			width: 7000,
			url: "https://dl.blocks.glass/u/c0818f07f5af465a/jetpack_cat2_qs5x9a0.75.jpg?ixlib=js-3.7.0&fm=webp&fit=max&w=7000",
			type: "image/webp",
		},
		{
			__typename: "ImageAsset",
			height: 7500,
			width: 7500,
			url: "https://dl.blocks.glass/u/c0818f07f5af465a/jetpack_cat2_qs5x9a0.75.jpg?ixlib=js-3.7.0&fm=webp&fit=max&w=7500",
			type: "image/webp",
		},
		{
			__typename: "ImageAsset",
			height: 8000,
			width: 8000,
			url: "https://dl.blocks.glass/u/c0818f07f5af465a/jetpack_cat2_qs5x9a0.75.jpg?ixlib=js-3.7.0&fm=webp&fit=max&w=8000",
			type: "image/webp",
		},
		{
			__typename: "ImageAsset",
			height: 8190,
			width: 8190,
			url: "https://dl.blocks.glass/u/c0818f07f5af465a/jetpack_cat2_qs5x9a0.75.jpg?ixlib=js-3.7.0&fm=webp&fit=max&w=8190",
			type: "image/webp",
		},
	],
	backgroundImage: {
		__typename: "ImageAsset",
		height: 2184,
		width: 1638,
		url: "https://dl.blocks.glass/u/c0818f07f5af465a/jetpack_cat2_qs5x9a0.75.jpg?ixlib=js-3.7.0&fm=webp&auto=format&fit=crop&rect=4914%2C3640%2C1638%2C910&h=2000&blur=1100&bri=-30&w=1638",
		type: "image/webp",
	},
}

export default Hologram
