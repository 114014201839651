import { InfluxDBWriter, influxWrite } from "../../lib/stats"
import { QueueInfo } from "../globals"
import createQueue from "../../queues/createQueue"

export const InfluxDbWriterJob: QueueInfo<InfluxDBWriter> = {
	concurrency: 5,
	queue: createQueue<InfluxDBWriter>("InfluxDbWriter", {
		redisHost: "default",
		defaultJobOptions: {
			priority: 100,
			removeOnComplete: {
				age: 60 * 60 * 24 * 3,
				count: 1000,
			},
		},
	}),
	process: async (job, done) => {
		try {
			await influxWrite(job.data)
			done(null, { success: true })
		} catch (e) {
			done(e as Error)
		}
	},
}
