import Nav from "components/Navigation/index"
import useHubspotSync from "hooks/useHubspotSync"
import useOnboardingStore from "hooks/useOnboardingStore"
import { useBlocksUserStore } from "hooks/useStore"
import dynamic from "next/dynamic"
import { ReactNode, useEffect, useState } from "react"
import Analytics from "./Analytics"
import { toastErrorStyle, toastSuccessStyle } from "./toastStyles"

interface LayoutProps {
	backgroundImage?: string | null
	children: ReactNode
}

export default function Layout({ backgroundImage = null, children }: LayoutProps) {
	const user = useBlocksUserStore((state) => state.dbUser)
	const [openPopup, setOpenPopup] = useState(false)
	const { getResumeStep, setCurrentStep, currentStep } = useOnboardingStore()

	useEffect(() => {
		if (user) {
			const resumeStep = getResumeStep(user)
			if (resumeStep) {
				setCurrentStep(resumeStep)
			}
		}
	}, [user])

	useEffect(() => {
		if (currentStep) {
			setOpenPopup(true)
		} else setOpenPopup(false)
	}, [currentStep])

	useHubspotSync()

	const NextNProgress = dynamic(() => import("nextjs-progressbar"), { ssr: false })
	const Toaster = dynamic(() => import("react-hot-toast").then((mod) => mod.Toaster), { ssr: false })
	const TermsBanner = dynamic(() => import("./TermsBanner").then((mod) => mod.TermsBanner), { ssr: false })
	const HelpScoutBeacon = dynamic(() => import("./HelpscoutBeacon"), {
		ssr: false,
	})
	const BulkUploaderTray = dynamic(
		() => import("./upload/BulkUploader").then((mod) => mod.BulkUploaderTray),
		{ ssr: false },
	)
	const OnboardingPopup = dynamic(() => import("./onboarding/OnboardingPopup"), { ssr: false })

	return (
		<div id="layout" className="lkg-layout">
			<Analytics />
			<NextNProgress
				color="#8E3FFB"
				height={2}
				options={{ easing: "ease-out", speed: 400, showSpinner: false }}
			/>
			<Nav />
			<Toaster
				toastOptions={{ success: toastSuccessStyle, error: toastErrorStyle }}
				position="bottom-right"
			/>

			{children}
			<div
				id="overlay"
				className="pointer-events-none fixed bottom-0 left-0 right-0 top-0 z-[9] w-screen"></div>
			<TermsBanner />
			<HelpScoutBeacon />
			<BulkUploaderTray />
			<OnboardingPopup
				open={openPopup}
				onClose={() => {
					setOpenPopup(false)
					// Prevent a visual bug where the content is missing while the popup fades away
					setTimeout(setCurrentStep, 500)
				}}
			/>
		</div>
	)
}
