import {
	Hologram,
	ImageAsset,
	Playlist,
	PlaylistItem,
	PlaylistPrivacy,
	Prisma,
	PrismaClient,
	User,
} from "@prisma/client"
import { Connection, connectionFromArraySlice, cursorToOffset } from "graphql-relay"
import { PaginationArgs } from "nexus/dist/plugins/connectionPlugin"
import { GPlaylist } from "../../graphql/server/types"
import { getIdOrUuid } from "../../lib/utils"
import { Holograms } from "./Holograms.model"
import { Users } from "./Users.model"

export function allWhereInput(args: PlaylistsQueryArgs): Prisma.PlaylistWhereInput {
	let where: Prisma.PlaylistWhereInput = {}

	if (args.privacy === undefined) {
		where.privacy = "PUBLIC"
	} else {
		where.privacy = args.privacy ?? undefined
	}

	if (args.userId) {
		where.userId = args.userId
	}

	return where
}

export function orderByInput(args: PlaylistsQueryArgs) {
	let orderBy: Prisma.PlaylistOrderByWithRelationInput[] = [{ privacy: "asc" }, { id: "desc" }]
	if (args.orderBy != null && args.orderBy.match(/:/)) {
		const orderArgs = args.orderBy.split(":")
		orderBy = [
			{
				[orderArgs[0]]: orderArgs[1],
			},
		]
	}
	return orderBy
}

export interface PlaylistsQueryArgs extends PaginationArgs {
	userId?: number
	/** Sort query by column:direction (e.g. `id:asc`) */
	orderBy?: string | null
	/**
	 * Show holograms with specific privacy setting. If false, then this will show all holograms
	 * @default PUBLIC
	 */
	privacy?: PlaylistPrivacy | null
}

export type PlaylistItemsQueryArgs = PaginationArgs & {
	playlistId: number
	discover?: boolean
}

type PlaylistQueryArgs = Prisma.PlaylistFindFirstArgs["where"] & {
	/** Helper for figuring which type it is */
	idOrUuid?: string
	/** The user who is trying to view the playlist */
	requestUserId?: number
	/** If true, will return a playlist even if it's private */
	allowPrivate?: boolean

	// If true, will show shadow banned content
	discover?: boolean
	include?: Prisma.PlaylistFindFirstArgs["include"]
}

export function hasPlaylistAccess(playlist: Partial<GPlaylist>, user: User): boolean {
	if (user.id == playlist.userId) return true
	if (user.role == "ADMIN") return true
	return false
}

export function Playlists(prisma: PrismaClient) {
	return {
		async discoverPlaylists(): Promise<Playlist[]> {
			const staffUser = await Users(prisma.user).staff()

			if (!staffUser) {
				throw new Error("Missing Staff User")
			}

			return await prisma.playlist.findMany({
				where: {
					userId: staffUser.id,
				},
				orderBy: {
					position: "asc",
				},
			})
		},

		async create(args: Omit<Prisma.PlaylistUncheckedCreateInput, "id" | "position">) {
			let highestPosition = await prisma.playlist.findFirst({
				where: { userId: args.userId },
				orderBy: { position: "desc" },
				select: { position: true },
			})

			let newPosition = highestPosition ? highestPosition.position + 1 : 1 // If no position exists, default to 1

			return await prisma.playlist.create({
				data: {
					title: args.title?.trim(),
					description: args.description?.trim(),
					featuredHologramId: args.featuredHologramId,
					isPublished: args.isPublished,
					privacy: args.privacy,
					userId: args.userId,
					position: newPosition,
				},
			})
		},

		async find(args: PlaylistQueryArgs) {
			let { idOrUuid, requestUserId, allowPrivate, include, ...where } = args

			// Find if we are using id or uuid
			if (idOrUuid) {
				where = {
					...where,
					...getIdOrUuid(idOrUuid),
				}
			}

			let whereUserCheck: any = {}
			if (requestUserId) {
				whereUserCheck = { userId: requestUserId }
				if (where.id) {
					whereUserCheck.id = where.id
				} else {
					whereUserCheck.uuid = where.uuid
				}
			}

			if (allowPrivate) {
				return await prisma.playlist.findFirst({ where, include })
			}

			if (where.id) {
				return await prisma.playlist.findFirst({
					where: {
						OR: [{ id: where.id, privacy: "PUBLIC" }, whereUserCheck],
					},
					include,
				})
			} else if (where.uuid) {
				return await prisma.playlist.findFirst({
					where: {
						OR: [{ uuid: where.uuid, privacy: "UNLISTED" }, whereUserCheck],
					},
					include,
				})
			}

			throw new Error("Must set an id or uuid")
		},

		/** Get all playlists (public/private/unlisted/unpulished) */
		async all(args: PlaylistsQueryArgs): Promise<Connection<Playlist>> {
			const where = allWhereInput(args)

			let orderBy: Prisma.PlaylistOrderByWithRelationInput[] = [{ privacy: "asc" }, { id: "desc" }]
			if (args.orderBy != null && args.orderBy.match(/:/)) {
				const orderArgs = args.orderBy.split(":")
				orderBy = [
					{
						[orderArgs[0]]: orderArgs[1],
					},
				]
			}

			return await ConnectionQueryHelper({
				object: prisma.playlist,
				args,
				where,
				orderBy,
			})
		},

		/** Delete the entire playlist and its associated PlaylistItems */
		async delete(id: number) {
			const playlist = await prisma.playlist.delete({ where: { id } })
			return playlist
		},

		/** Get the thumbnail from the first hologram  */
		async getThumbnail(id: number, width: number): Promise<Partial<ImageAsset | null>> {
			const hologram = await this.firstHologram(id)
			if (hologram) {
				// @ts-ignore
				return await Holograms(prisma).thumbnail(hologram, width)
			}
			return null
		},

		itemsWhereInput(args: PlaylistItemsQueryArgs): Prisma.PlaylistItemWhereInput {
			return {
				playlistId: args.playlistId,
				hologram: {
					privacy: {
						in: ["PUBLIC", "UNLISTED"],
					},
					isShadowBan: args.discover ? undefined : false,
					isPublished: true,
				},
			}
		},

		async items(args: PlaylistItemsQueryArgs): Promise<Connection<PlaylistItem>> {
			return await ConnectionQueryHelper({
				object: prisma.playlistItem,
				args,
				orderBy: [{ position: "asc" }, { id: "asc" }],
				where: {
					...this.itemsWhereInput(args),
				},
			})
		},

		async firstHologram(playlistId: number): Promise<Hologram | null> {
			return await prisma.playlistItem
				.findFirst({
					where: { playlistId: playlistId },
					take: 1,
					orderBy: [{ position: "asc" }, { id: "asc" }],
				})
				.hologram()
		},

		async updatePlaylistPosition(id: number, newPosition: number) {
			const currentItem = await prisma.playlist.findUnique({
				where: { id: id },
			})

			if (!currentItem) {
				throw new Error("Playlist not found")
			}

			const oldPosition = currentItem.position

			if (!oldPosition) {
				throw new Error("Playlist did not have a position")
			}

			const user = currentItem.userId

			// Use a transaction to ensure atomic updates
			return await prisma.$transaction(async (prisma) => {
				if (newPosition < oldPosition) {
					await prisma.playlist.updateMany({
						where: {
							userId: user,
							position: {
								gte: newPosition,
								lt: oldPosition,
							},
						},
						data: {
							position: {
								increment: 1,
							},
						},
					})
				} else {
					await prisma.playlist.updateMany({
						where: {
							userId: user,
							position: {
								gt: oldPosition,
								lte: newPosition,
							},
						},
						data: {
							position: {
								decrement: 1,
							},
						},
					})
				}

				return await prisma.playlist.update({
					where: { id: id },
					data: { position: newPosition },
					include: {
						playlistItems: {
							include: {
								syncItems: true,
								hologram: {
									include: {
										imageAssets: true,
										Settings: true,
										user: { include: { avatar: true } },
									},
								},
							},
						},
						user: { include: { avatar: true } },
					},
				})
			})
		},
	}
}

interface ConnectionQueryArgs {
	args: Partial<PaginationArgs>
	where?: any
	orderBy?: any
	object: any // TODO how do we properly extend Prisma delegates
}

export async function ConnectionQueryHelper<T>(props: ConnectionQueryArgs): Promise<Connection<T>> {
	const { args, object, where, orderBy } = props
	const offset = args.after ? cursorToOffset(args.after) + 1 : 0
	if (isNaN(offset)) throw new Error("cursor is invalid")

	if (!args.first) {
		args.first = 25
	}

	if (args.first > 100) {
		args.first = 100
	}

	const [count, items] = await Promise.all([
		object.count({ where }),
		object.findMany({
			take: args.first,
			skip: offset,
			orderBy,
			where,
		}),
	])

	return connectionFromArraySlice<T>(
		items,
		{
			first: args.first,
			after: args.after,
		},
		{ sliceStart: offset, arrayLength: count },
	)
}
