// For trpc code only: This is temporary until the app is fully strictNullCheck=true

import { trpc } from "lib/trpc/trpc"
import { useEffect, useState } from "react"

export default function useHologramDeviceView(hologramId: number | undefined, skip: boolean = false) {
	const [viewed, setViewed] = useState<number>(0)
	const stats = trpc.stats.hologramPageView.useMutation()

	useEffect(() => {
		if (!hologramId) return

		if (viewed != hologramId && !skip) {
			setViewed(hologramId)
			stats.mutate({ lookup: hologramId.toString(), isDeviceView: true })
		}
	}, [hologramId, skip])
}
