export type Bots = {
	discord?: boolean
	slack?: boolean
	twitter?: boolean
	sms?: boolean
	embedly?: boolean
	iframely?: boolean
	gitbook?: boolean
}

export default function getCrawlerBot(userAgent: string): Bots {
	const macosSMS = check(userAgent, [
		"Macintosh;",
		"AppleWebKit",
		"Safari",
		"Facebot",
		"Twitterbot",
		"facebookexternalhit",
	])

	const iosSMS = check(userAgent, ["iPhone;", "AppleWebKit", "Safari"])

	return {
		discord: userAgent.match(/Discordbot/i) != null,
		slack: userAgent.match(/Slackbot/i) != null,
		twitter: userAgent.match(/Twitterbot/i) != null && !macosSMS,
		embedly: userAgent.match(/Embedly/i) != null,
		iframely: userAgent.match(/Iframely/i) != null,
		gitbook: userAgent.match(/Gitbook/i) != null,
		sms: false, // macosSMS || iosSMS,
	}
}

function check(userAgent: string, params: string[]): boolean {
	const results: boolean[] = params.map((p) => userAgent.match(p) != null)

	return results.every((v) => v === true)
}

// MacOS SMS
// Mozilla/5.0 (Macintosh; Intel Mac OS X 10_11_1) AppleWebKit/601.2.4 (KHTML, like Gecko) Version/9.0.1 Safari/601.2.4 facebookexternalhit/1.1 Facebot Twitterbot/1.0

// iOS SMS
// Mozilla/5.0 (iPhone; CPU iPhone OS 15_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.5 Mobile/15E148 Safari/604.1

// Regular Safari load (desktop)
// Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.4 Safari/605.1.15

// Safari load (mobile)
// Mozilla/5.0 (iPhone; CPU iPhone OS 15_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.5 Mobile/15E148 Safari/604.1
