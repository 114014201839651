import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

import { SerializeOptions, serialize } from "cookie"

export function timeout(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

export function formatBytes(b: number) {
	const units = ["bytes", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
	let l = 0
	let n = b

	while (n >= 1024) {
		n /= 1024
		l += 1
	}

	return `${n.toFixed(n >= 10 || l < 1 ? 0 : 1)}${units[l]}`
}

/** Used for any api queries that stringify the ID */
export function getIdOrUuidLegacy(id: string | null): any {
	if (!id) return {}

	if (id.match(/-/)) {
		return { uuid: id }
	} else {
		return { id: id }
	}
}

export function getIdOrUuid(id: string): any {
	if (id === undefined) return {}

	if (id === "") return {}

	if (id.match(/-/)) {
		return { uuid: id }
	} else {
		const numericId = parseInt(id)

		if (isNaN(numericId)) return {}

		return { id: numericId }
	}
}

export function validateEmail(email: string | null): boolean {
	// http://emailregex.com/
	return (
		email?.match(
			/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
		) != null
	)
}

interface ParsedQuiltConfig {
	quiltRows: number
	quiltCols: number
	aspectRatio: number
	quiltTileCount: number
}

export function getQuiltConfigFromFilename(filename: string): ParsedQuiltConfig | null {
	const quiltSettingsMatch = filename?.match(/(_?qs([0-9]+)x([0-9]+)a(([0-9]*[.])?[0-9]+))/)
	if (quiltSettingsMatch) {
		const quiltCols = parseInt(quiltSettingsMatch[2])
		const quiltRows = parseInt(quiltSettingsMatch[3])

		return {
			quiltCols,
			quiltRows,
			aspectRatio: parseFloat(quiltSettingsMatch[4]),
			quiltTileCount: quiltCols * quiltRows,
		}
	} else return null
}

export function numberWithCommas(x: number | null | undefined): string {
	if (!x) return "0"
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function shuffle<T>(array: T[]): T[] {
	let currentIndex = array.length
	let randomIndex = 0

	// While there remain elements to shuffle.
	while (currentIndex != 0) {
		// Pick a remaining element.
		randomIndex = Math.floor(Math.random() * currentIndex)
		currentIndex--

		// And swap it with the current element.
		;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
	}

	return array
}

export function titlecase(str: string | null | undefined) {
	return str
		?.toLowerCase()
		?.split(" ")
		?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		?.join(" ")
}

/**
 * Wraps emojis in a span so they can be styled
 */
export function wrapEmojis(txt: string | undefined | null): string | undefined {
	const regex =
		// @ts-ignore
		/\p{RI}\p{RI}|\p{Emoji}(\p{EMod}+|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})?(\u{200D}\p{Emoji}(\p{EMod}+|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})?)+|\p{EPres}(\p{EMod}+|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})?|\p{Emoji}(\p{EMod}+|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})/gu
	return txt?.replace(regex, function (emoji) {
		return `<span class="emoji" role="img" aria-hidden="true">${emoji}</span>`
	})
}

/** Removes the quilt config from a string */
export function stripQuiltConfig(text: string): string {
	return text.replace(/_?\(?(qs[0-9]+x[0-9]+a([0-9]*[.])?[0-9]+)\)?/g, "")
}

export function mapEdgesToNodes<T>(
	edges?: ({ node?: T | null | undefined } | null)[] | null | undefined,
): T[] {
	return (
		edges
			?.filter((edge): edge is NonNullable<typeof edge> => edge !== null)
			?.map((edge) => edge.node)
			?.filter((node): node is NonNullable<typeof node> => node !== null && node !== undefined) ?? []
	)
}

export type CookiePayload = SerializeOptions & { name: string; value: string }

export const serializeCookie = (cookie: CookiePayload) => {
	const { name, value, expires, sameSite, ...options } = cookie
	return serialize(name, value, {
		...options,
	})
}

export const TEST_EMAIL_USER_REGEX = /test-user-[a-zA-Z0-9]{0,7}@lookingglassfactory\.com/g

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}
