import { type Session } from "@auth0/nextjs-auth0"
import { createClient, SupabaseClient as BaseClient, SupabaseClientOptions } from "@supabase/supabase-js"

import { decodeJwt, SignJWT } from "jose"
import { NextRequest } from "next/server"
import { Database } from "supabase/schema"

interface SupabaseClientArgs {
	accessToken?: string
	session?: Session | null
}

function assert(assertion: string | null, message: string) {
	if (!assertion) {
		throw new Error(message)
	}
}

export type SupabaseClient = BaseClient<Database>
export type Tables<T extends keyof Database["public"]["Tables"]> = Database["public"]["Tables"][T]["Row"]
export type Enums<T extends keyof Database["public"]["Enums"]> = Database["public"]["Enums"][T]
export type SupabaseUser = Tables<"User"> & { uuid: string }

export async function getSupabaseEdgeClient(req: NextRequest) {
	const bearer = req.headers.get("Authorization")?.replace("Bearer ", "")
	if (!bearer) return getSupabase()

	const decoded = decodeJwt(bearer)
	const payload = {
		exp: decoded.exp,
		userId: decoded.sub,
	}

	assert(process.env.SUPABASE_JWT_SECRET!, "SUPABASE_JWT_SECRET not set!")

	const secret = new TextEncoder().encode(process.env.SUPABASE_JWT_SECRET)
	const accessToken = await new SignJWT(payload).setProtectedHeader({ alg: "HS256" }).sign(secret)

	const client = getSupabase({ accessToken })

	return client
}

export function getSupabase(args?: SupabaseClientArgs | undefined): SupabaseClient {
	const accessToken = args?.session?.accessToken ?? args?.accessToken

	const options = { auth: { persistSession: false } } as SupabaseClientOptions<"public">

	if (accessToken) {
		options.global = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		}
	}

	assert(process.env.NEXT_PUBLIC_SUPABASE_URL!, "NEXT_PUBLIC_SUPABASE_URL not set!")
	assert(process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!, "NEXT_PUBLIC_SUPABASE_ANON_KEY not set!")

	const supabase = createClient<Database>(
		process.env.NEXT_PUBLIC_SUPABASE_URL!,
		process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
		options,
	)

	return supabase
}
