import { IconArgs } from "./FacebookIcon"

export default function SoftwareDownloadIcon(args: IconArgs) {
	return (
		<svg
			width="23"
			height="28"
			viewBox="0 0 23 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={args.className}>
			<path d="M5.3009 20.2796L6.13919 19.9248L11.1003 22.0317L16.0632 19.9248L16.8971 20.2796L11.1003 22.7422L5.3009 20.2796Z" />
			<path d="M11.1012 18.3207L6.14095 20.4274L5.3009 20.0735L11.1012 17.6094L16.898 20.0726L16.0641 20.4274L11.1012 18.3207Z" />
			<path d="M0 22.7523V17.5949L11.1045 12.6162L22.209 17.5949V22.7523L11.1045 27.7309L0 22.7523ZM11.1045 27.0143L21.0628 22.5496L15.4728 20.1758L21.0672 17.7993L11.1045 13.332L1.11889 17.8099L6.73359 20.1749L1.14264 22.5479L11.1045 27.0143V27.0143ZM21.5581 22.0497V18.3018L17.1432 20.1767L21.5581 22.0506V22.0497ZM0.650929 22.0471L5.05789 20.1758L0.650929 18.3045V22.0462V22.0471Z" />
			<path d="M16.8979 8.4208H13.6029V3.29531C13.6029 3.19821 13.5644 3.10509 13.4957 3.03643C13.427 2.96777 13.3339 2.9292 13.2368 2.9292H8.84354C8.74644 2.9292 8.65332 2.96777 8.58466 3.03643C8.516 3.10509 8.47743 3.19821 8.47743 3.29531V8.4208H5.18247C5.1141 8.42049 5.04702 8.43933 4.98881 8.47518C4.93059 8.51104 4.88359 8.56247 4.8531 8.62367C4.82262 8.68486 4.80988 8.75337 4.81633 8.82143C4.82278 8.88949 4.84815 8.95439 4.88958 9.00877L10.7473 16.697C10.7814 16.7419 10.8255 16.7783 10.8761 16.8034C10.9266 16.8285 10.9823 16.8415 11.0387 16.8415C11.0951 16.8415 11.1508 16.8285 11.2014 16.8034C11.2519 16.7783 11.296 16.7419 11.3301 16.697L17.1878 9.00877C17.2291 8.95459 17.2545 8.88996 17.261 8.82216C17.2676 8.75436 17.2551 8.68607 17.225 8.62498C17.1948 8.56389 17.1483 8.51241 17.0905 8.47634C17.0327 8.44027 16.966 8.42104 16.8979 8.4208Z" />
			<path d="M8.84352 1.46464H13.2368C13.3339 1.46464 13.427 1.42606 13.4957 1.35741C13.5643 1.28875 13.6029 1.19563 13.6029 1.09853C13.6029 1.00143 13.5643 0.90831 13.4957 0.839652C13.427 0.770994 13.3339 0.732422 13.2368 0.732422H8.84352C8.74643 0.732422 8.65331 0.770994 8.58465 0.839652C8.51599 0.90831 8.47742 1.00143 8.47742 1.09853C8.47742 1.19563 8.51599 1.28875 8.58465 1.35741C8.65331 1.42606 8.74643 1.46464 8.84352 1.46464Z" />
		</svg>
	)
}
