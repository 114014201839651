import Queue, { Job } from "bull"
import { getAdaptiveSizedQuilts } from "../../lib/utils.hologram"
import { Holograms } from "../../prisma/models/Holograms.model"
import { QueueInfo } from "../globals"
import { WarmURL } from "./warmURL"
import createQueue from "../../queues/createQueue"
import { getS3Key } from "../../lib/utils.s3"
import axios from "axios"
import { getCenterRectFromQuilt } from "../../lib/utils.edge"

type Args = {
	/** The hologram id */
	id: number
}

/** Fetch all the possible quilt urls so that they are pre-generated */
export const WarmQuiltCache: QueueInfo<Args> = {
	queue: createQueue<Args>("WarmQuiltCache", {
		redisHost: "default",
		defaultJobOptions: {
			priority: 50,
			removeOnComplete: 3,
		},
	}),
	process: async (job, done) => {
		// Loaded in as dynamic import to avoid circular issue with Holograms.ts
		const prisma = (await import("../../lib/prisma")).default

		try {
			await job.log(`querying ${job.data.id}`)
			await prisma.hologram.findFirst({
				where: {
					id: job.data.id,
				},
			})

			let hologram = await prisma.hologram.findFirst({
				where: {
					id: job.data.id,
				},
				include: {
					user: true,
				},
			})

			if (!hologram?.id) {
				throw new Error(`Hologram not found`)
			}

			const sourceImages = await Holograms(prisma).sourceImages(hologram.id)
			const sourceImage = sourceImages?.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())?.[0]

			//force cdn to cache thumbnail
			if (sourceImage) {
				// @ts-ignore
				const rect = getCenterRectFromQuilt(hologram, sourceImage)
				const src = getS3Key(sourceImage.url)
				const width = 600
				const aspectRatio = !!hologram.aspectRatio ? hologram.aspectRatio : 1.78
				const height = width / aspectRatio
				const url = `${process.env.NEXT_PUBLIC_AWS_IMAGE_CDN}${src}?format=jpg&width=${width}&height=${height}&rect=${rect}&fit=fill`

				await axios.head(url)
			}

			if (!sourceImages) {
				throw new Error("No source images found")
			}
			const quilts = getAdaptiveSizedQuilts({ sourceImages })

			let totalJobs = 0
			for await (const quilt of quilts) {
				if (!quilt.url) continue
				await WarmURL.queue.add({ url: quilt.url })
				totalJobs++
			}

			// Also generate AVIF versions too
			for await (const quilt of quilts) {
				if (!quilt.url) continue
				const url = quilt.url.replace("fm=webp", "fm=avif")
				await WarmURL.queue.add({ url })
				totalJobs++
			}

			done(null, { queuedJobs: totalJobs++ })
		} catch (e) {
			done(e as Error)
		}
	},
}

interface FetchHeadArgs {
	url: string
	job: Job
	done: (err: Error) => void
}
