import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject, from } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { relayStylePagination } from "@apollo/client/utilities"
import { addBreadcrumb, captureException } from "@sentry/nextjs"
import merge from "deepmerge"
import { IncomingHttpHeaders } from "http"
import isEqual from "lodash/isEqual"
import { useMemo } from "react"
import { IS_DEV } from "./environment"

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__"

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined

type CreateApolloClientOptions = {
	headers?: IncomingHttpHeaders | null
	/**
	 * If SSR, then the request will be authorized with the super token giving full access to the API
	 * @default true
	 */
	isSuperApiRequest?: boolean
}

function createApolloClient({ headers, isSuperApiRequest }: CreateApolloClientOptions = {}) {
	// isomorphic fetch for passing the cookies along with each GraphQL request
	const enhancedFetch = async (url: RequestInfo | URL, init?: RequestInit) => {
		if (IS_DEV && process.env.DEBUG_GRAPHQL_QUERIES === "1") {
			const styles = ["color: white", "background: #b844e6", "padding: 2px"].join(";")
			let data = JSON.parse(init?.body?.toString() ?? "{}")
			console.log("%cGraphQL =>", styles, data.operationName, data.variables)
		}
		return fetch(url, {
			...init,
			headers: {
				...init?.headers,
				"Access-Control-Allow-Origin": "*",
				// here we pass the cookie along for each request
				Cookie: headers?.cookie ?? "",
			},
		}).then((response) => response)
	}

	// Do we need to host our graphql server elsewhere?
	const baseUrl = `${process.env.BASE_URL}/api/graphql`
	const isSSR = typeof window === "undefined"

	// Make sure SSR calls have full access to graphql API
	// don't add this if you passed in your own authorization token; this is used by the e2e test suite
	if (isSSR && headers?.Authorization === undefined) {
		headers ||= {}
		if (isSuperApiRequest) headers["Authorization"] = `Bearer ${process.env.BLOCKS_API_SECRET_TOKEN}`
	}

	const errorLink = onError(({ graphQLErrors, networkError, operation, response }) => {
		addBreadcrumb({
			category: "graphql",
			data: operation,
		})
		if (graphQLErrors)
			graphQLErrors.forEach((gqlError) => {
				const { message, locations, path } = gqlError
				console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
				captureException(gqlError)
			})

		if (networkError) {
			console.log(`[Network error]: ${networkError}`)
			for (const error in response?.errors) {
				captureException(error)
			}
		}
	})

	const httpLink = new HttpLink({
		uri: baseUrl,
		headers: headers as Record<string, string>,
		credentials: "same-origin", // Additional fetch() options like `credentials` or `headers`
		fetchOptions: {
			mode: "cors",
		},
		fetch: enhancedFetch,
	})

	const linkChain = from([errorLink, httpLink])

	return new ApolloClient({
		ssrMode: isSSR,
		link: linkChain,
		connectToDevTools: IS_DEV,
		cache: new InMemoryCache({
			typePolicies: {
				Query: {
					fields: {
						hologram: { keyArgs: ["id", "uuid", "lookup"] },
						hologramFindById: { keyArgs: ["id", "uuid", "lookup"] },
						holograms: relayStylePagination(["userId", "username", "ids", "orderBy"]),
						myHolograms: relayStylePagination(),
						users: relayStylePagination(),
						user: {
							keyArgs: ["id", "subId", "username"],
						},
						playlists: relayStylePagination(),
						playlistItems: relayStylePagination(),
					},
				},
			},
		}),
	})
}

type InitialState = NormalizedCacheObject | undefined
type IInitializeApollo = CreateApolloClientOptions & {
	initialState?: InitialState | null
}

const apolloDefaults: IInitializeApollo = {
	headers: null,
	initialState: null,
	isSuperApiRequest: true,
}

export const initializeApollo = (args: IInitializeApollo = apolloDefaults) => {
	const { headers, initialState, isSuperApiRequest } = { ...apolloDefaults, ...args }

	const _apolloClient = apolloClient ?? createApolloClient({ headers, isSuperApiRequest })

	// If your page has Next.js data fetching methods that use Apollo Client, the initial state
	// gets hydrated here
	if (initialState) {
		// Get existing cache, loaded during client side data fetching
		const existingCache = _apolloClient.extract()

		// Merge the existing cache into data passed from getStaticProps/getServerSideProps
		const data = merge(initialState, existingCache, {
			// combine arrays using object equality (like in sets)
			arrayMerge: (destinationArray, sourceArray) => [
				...sourceArray,
				...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
			],
		})

		// Restore the cache with the merged data
		_apolloClient.cache.restore(data)
	}

	// For SSG and SSR always create a new Apollo Client
	if (typeof window === "undefined") return _apolloClient

	// Create the Apollo Client once in the client
	if (!apolloClient) apolloClient = _apolloClient

	return _apolloClient
}

/** Any data that is loaded server side (e.g. getStaticProps) will be saved into the client cache.
 * If you make the same call client side, it will load instantly from cache. */
export function addApolloState(client: ApolloClient<NormalizedCacheObject>, pageProps: any) {
	if (pageProps?.props) {
		pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
	}

	return pageProps
}

export function useApollo(pageProps: any) {
	const state = pageProps[APOLLO_STATE_PROP_NAME]
	const store = useMemo(
		() =>
			initializeApollo({
				initialState: state,
			}),
		[state],
	)
	return store
}
