import React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 2" viewBox="0 0 33.33 32.38" {...props}>
		<path
			d="M17.47 3.54c-.5-.28-1.11-.28-1.61 0L5.1 9.5l10.75 5.98c.5.28 1.12.28 1.62 0L28.22 9.5 17.46 3.54ZM14.24.62c1.51-.83 3.34-.83 4.84 0l13.39 7.41c.53.29.86.85.86 1.46s-.33 1.16-.86 1.46l-3.4 1.89 3.4 1.88c.53.29.86.85.86 1.46s-.33 1.16-.86 1.46l-3.38 1.88 3.38 1.87c.53.29.86.85.86 1.46s-.33 1.16-.86 1.46l-13.38 7.44c-1.51.84-3.35.84-4.86 0L.86 24.31c-.53-.3-.86-.85-.86-1.46s.33-1.16.86-1.46l3.38-1.87-3.38-1.88c-.53-.29-.86-.85-.86-1.46s.33-1.16.86-1.46l3.4-1.88-3.4-1.89C.33 10.66 0 10.1 0 9.49s.33-1.16.86-1.46L14.24.63ZM7.67 21.43 5.1 22.85l10.75 5.98c.5.28 1.12.28 1.62 0l10.75-5.98-2.57-1.42-6.56 3.65c-1.51.84-3.35.84-4.86 0l-6.56-3.65Zm11.42-3.04 6.54-3.64 2.59 1.44-10.75 5.98c-.5.28-1.12.28-1.62 0L5.1 16.19l2.59-1.44 6.54 3.64c1.51.84 3.35.84 4.86 0Z"
			data-name="Layer 1"
			style={{
				fill: "#fff",
				fillRule: "evenodd",
				strokeWidth: 0,
			}}
		/>
	</svg>
)
export default SvgComponent
