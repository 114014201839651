import { findSourceImage } from "@/prisma/models"
import { getCDNUrl } from "./cdn"
import { EmbedHologram } from "./hologramProps"

/** Return a url for this hologram of the original size, with the specified image type. */
export function getHopsImage(hologram: EmbedHologram, imageType: string = "jpeg") {
	const sourceImage = findSourceImage(hologram)
	if (!sourceImage) return null
	// do some math for the image size to make sure hops will handle it correctly
	let w = sourceImage.width
	let h = sourceImage.height
	if (w > 8192) {
		h = h * (8192 / w)
		w = 8192
	}
	if (h > 8192) {
		w = w * (8192 / h)
		h = 8192
	}
	w = Math.floor(w / hologram.quiltCols!) * hologram.quiltCols!
	h = Math.floor(h / hologram.quiltRows!) * hologram.quiltRows!

	const aspect = sourceImage.width / sourceImage.height

	const cdnUrl = getCDNUrl(sourceImage.url, { width: w, height: w / aspect, format: "jpeg", fit: "cover" })

	return {
		...sourceImage,
		url: cdnUrl,
		width: w,
		height: Math.round(w / aspect),
		type: `image/${imageType || "webp"}`,
	}
}
