import {
	CopyObjectCommand,
	DeleteObjectCommand,
	HeadObjectCommand,
	PutObjectCommand,
	S3ServiceException,
} from "@aws-sdk/client-s3"
import { createPresignedPost, PresignedPost } from "@aws-sdk/s3-presigned-post"
import axios from "axios"
import FormData from "form-data"
import { v4 } from "uuid"
import { ImageDetailsResponse, probeImageUrl } from "./probeImage"
import { getS3Client } from "./s3"
import { MAX_UPLOAD_BYTES } from "./validation"

export async function getPrismaClient() {
	return (await import("./prisma")).default
}

const getCloudFrontClient = async () => {
	const { CloudFront } = await import("@aws-sdk/client-cloudfront")

	if (!process.env.NEXT_AWS_ACCESS_KEY || !process.env.NEXT_AWS_SECRET_KEY) {
		throw new Error("Missing NEXT_AWS_ACCESS_KEY or NEXT_AWS_SECRET_KEY")
	}

	const client = new CloudFront({
		region: process.env.NEXT_AWS_REGION,
		credentials: {
			accessKeyId: process.env.NEXT_AWS_ACCESS_KEY,
			secretAccessKey: process.env.NEXT_AWS_SECRET_KEY,
		},
	})

	return client
}

export async function cloudfrontInvalidate(key: string) {
	return new Promise(async (resolve, reject) => {
		const cloudfront = await getCloudFrontClient()
		console.log("cloudfrontInvalidate", key, process.env.NEXT_AWS_CLOUDFRONT_DISTRIBUTION_ID)

		// Ensure key is an absolute path
		if (key.at(0) !== "/") {
			key = `/${key}`
		}

		cloudfront.createInvalidation(
			{
				DistributionId: process.env.NEXT_AWS_CLOUDFRONT_DISTRIBUTION_ID ?? "",
				InvalidationBatch: {
					Paths: {
						Quantity: 2,
						Items: [key, key + "*"],
					},
					CallerReference: Date.now().toString(),
				},
			},
			(err, data) => {
				if (err) {
					console.error("cloudfrontInvalidate failed", err)
					reject(err)
				} else {
					resolve(true)
				}
			},
		)
	})
}

export async function s3UpdateFileName(key: string, filename: string) {
	return new Promise(async (resolve, reject) => {
		const s3 = await getS3Client()

		const command = new CopyObjectCommand({
			Bucket: process.env.NEXT_AWS_S3_BUCKET_NAME ?? "",
			CopySource: `${process.env.NEXT_AWS_S3_BUCKET_NAME}/${key}`,
			Key: key,
			MetadataDirective: "REPLACE",
			ACL: "public-read",
			ContentDisposition: `attachment; filename="${filename}"`,
		})

		s3.send(command)
			.then(() => resolve(true))
			.catch(reject)
	})
}

/** Generate a random S3 object key, and make sure that it does not already exist. */
export async function s3GenerateRandomKey(filename: string): Promise<string> {
	// Generate a random S3 object key, and make sure that it does not already exist.
	let key: string | null = null
	let objectExists = false
	let failsafe = 10 // try again this many times before giving up

	do {
		key = createKeyFromFilename(filename)
		objectExists = await s3ObjectExists(process.env.NEXT_AWS_S3_BUCKET_NAME!, key!)
	} while (objectExists && --failsafe > 0)

	if (objectExists || !key) throw new Error("Could not generate a unique upload filename.")

	return key
}

/** Create a random hash key path for S3. Used primarly for generating a secret URL for AWS */
export function createKeyFromFilename(filename: string): string {
	const randomId = v4().replaceAll("-", "").substr(0, 16) // get 16 random uuid chars
	let cleanFilename = decodeURI(filename)
		.replaceAll(" ", "_")
		.replaceAll(/[^a-zA-Z0-9_\.\-]+/gi, "") // remove extraneous characters
	return `u/${randomId}/${cleanFilename}` // prefix with 'u' and make sure there's a filename part
}

export async function s3ObjectExists(bucket_name: string, key: string) {
	const s3 = await getS3Client()

	try {
		await s3.send(new HeadObjectCommand({ Bucket: bucket_name, Key: key }))
	} catch (err) {
		if (err instanceof S3ServiceException && err.name === "NotFound") {
			return false
		}
	}

	return true
}

/** Upload a file from a URL to S3
 * @return The full S3 URL of the uploaded file
 */
export async function s3UploadFromUrl(url: string): Promise<string> {
	const filename = url.split("/").pop()
	if (!filename) throw new Error("Could not get filename from URL")

	return new Promise<string>(async (resolve, reject) => {
		axios.get(encodeURI(url), { responseType: "arraybuffer" }).then(async (response) => {
			const buffer = Buffer.from(response.data, "binary")
			const key = await s3GenerateRandomKey(filename)

			const s3 = await getS3Client()
			const command = new PutObjectCommand({
				Bucket: process.env.NEXT_AWS_S3_BUCKET_NAME!,
				Key: key,
				Body: buffer,
				ACL: "public-read",
			})

			await s3
				.send(command)
				.then(() => resolve(createS3UrlFromKey(key)))
				.catch(reject)
		})
	})
}

export function createS3UrlFromKey(key: string) {
	return `https://s3.amazonaws.com/${process.env.NEXT_AWS_S3_BUCKET_NAME}/${key}`
}

/** Check if a URL is on our S3 bucket already */
export function isS3Url(url: string) {
	if (url.includes(`${process.env.NEXT_AWS_S3_BUCKET_NAME}.s3.amazonaws.com`)) return true
	if (url.includes(`s3.amazonaws.com/${process.env.NEXT_AWS_S3_BUCKET_NAME}`)) return true
	return false
}

/** Get the S3 URL of a file, or upload it if it's not already on S3 */
export async function getS3UrlOrUpload(url: string) {
	let imageDetails: ImageDetailsResponse | undefined = undefined

	// if it's one of our S3 urls, then it should be a public image and we need to strip the query params
	// this can happen when a client needlessly passes back the full signed upload URL instead of just the key
	if (isS3Url(url)) {
		url = url.split("?")[0] // remove query params
	}

	let s3Url: string = url

	try {
		imageDetails = (await probeImageUrl(url)) ?? undefined
	} catch (e) {
		throw new Error(`Invalid image URL ${url}`)
	}

	if (!isS3Url(url)) {
		// Let's upload it to our bucket
		s3Url = await s3UploadFromUrl(url)
	} else {
		// Let's make sure it doesnt exist already before adding it to the DB.
		// This is to make sure we dont have duplicate pointers to the same S3 image and prevent
		// bad actors from deleting images they dont own.
		const prisma = await getPrismaClient()
		const image = await prisma.imageAsset.findFirst({ where: { url } })
		if (image) {
			console.error("You are not allowed to create a hologram from this image. Re-uploading...")
			s3Url = await s3UploadFromUrl(url)
		}
	}

	return {
		url: s3Url,
		details: imageDetails,
	}
}

export async function s3CreatePresignedPost(
	key: string,
	opts?: { Expires?: number; Fields?: { [key: string]: string } },
) {
	const s3 = await getS3Client()
	const { Fields, ...rest } = opts || {}

	const post = await createPresignedPost(s3, {
		Bucket: process.env.NEXT_AWS_S3_BUCKET_NAME!,
		Key: key,
		Fields: {
			...Fields,
			acl: "public-read",
		},
		Conditions: [["content-length-range", 0, MAX_UPLOAD_BYTES]],
	})
	post.url = post.url.endsWith("/") ? post.url.slice(0, -1) : post.url
	return post
}

export function extractS3Key(s3Url: string): string | null {
	const parsedUrl = new URL(s3Url)

	// Get the path part of the URL, which includes the S3 key
	const path = parsedUrl.pathname

	// Remove the leading slash if it exists
	const key = path.startsWith("/") ? path.slice(1) : path

	return key || null
}

export function uploadBufferToPresignedPost(url: string, fields: PresignedPost["fields"], data: Buffer) {
	const formData = new FormData()

	Object.entries(fields as Record<string, any>).forEach(([key, value]) => {
		formData.append(key, value)
	})
	formData.append("file", data)
	return axios.post(url, formData, { headers: formData.getHeaders() })
}

export async function deleteS3Object(Key: string) {
	const s3 = await getS3Client()
	const command = new DeleteObjectCommand({ Bucket: process.env.NEXT_AWS_S3_BUCKET_NAME!, Key })
	return await s3.send(command)
}
