import { IconArgs } from "./FacebookIcon"

export default function LKGFolderIcon(args: IconArgs) {
	return (
		<svg
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 340 340"
			// style="enable-background:new 0 0 340 340;"
			xmlSpace="preserve"
			className={args.className}>
			<g>
				<polygon className="st0" points="178.1,177 160.2,184.6 178,192.3 195.9,184.7  " />
				<polygon
					className="st0"
					points="126.1,170.2 146.7,179 178,165.5 209.2,178.8 229.7,170.1 177.9,146.8  "
				/>
				<polygon
					className="st0"
					points="178.2,203.7 146.9,190.3 126.5,199 178.1,222.3 229.8,199 209.3,190.3  "
				/>
				<polygon className="st0" points="118.9,178.3 118.9,191.1 133.8,184.7  " />
				<polygon className="st0" points="237.4,191 237.4,178.1 222.4,184.5  " />
				<polygon
					className="st0"
					points="148.4,63.2 88.2,63.2 88.2,70.8 125,70.8 141.7,102.2 291.4,102.2 299.8,189.2 306.1,93.9 166.5,93.9     "
				/>
				<path
					className="st0"
					d="M135,113.4L118.3,82H49.5l13.8,170.2h231.4l-13.5-138.8H135z M247.9,202.3l-69.8,31.6l-69.8-31.6v-35.3   l69.8-31.6l69.8,31.6V202.3z"
				/>
			</g>
		</svg>
	)
}
