import Link from "next/link"
import React, { MouseEventHandler, forwardRef } from "react"
import { Tooltip } from "./Tooltip"

export interface LinkWrapperProps extends Omit<React.HTMLProps<HTMLAnchorElement>, "wrap"> {
	href?: string
	children: any
	target?: string
	className?: string
	tooltip?: string
	/** Wrap in an <a> tag
	 * @default true
	 */
	wrap?: boolean
	/**
	 * Optionally disable linking
	 * @default true
	 */
	link?: boolean
	onClick?: MouseEventHandler<HTMLAnchorElement>
	id?: string
}

const defaults: LinkWrapperProps = {
	href: "",
	children: <></>,
	wrap: true,
	link: true,
}

/**
 * Link helper for making it easier to not have to think about when to use Link vs <a>
 * or if you need to set rel="noreferrer", etc
 *
 * History: This was written to extend the Nextjs <Link /> component. Nextjs has
 * since made lots of changes/improvements to their Link which makes this component
 * less useful. However, it's still useful for wrapping links in a tooltip.
 */
export const Linky = forwardRef<HTMLAnchorElement, LinkWrapperProps>((input, ref) => {
	const props = { ...defaults, ...input }
	const { href, children, wrap, link, id, ...rest } = props
	const isInternal = href && (href.startsWith("/") || href.startsWith("#"))
	const isApi = href && href.startsWith("/api")

	if (!link) {
		return props.children
	}

	// Use <a> link for any api or non-internal routes
	if (isApi || !isInternal || props.target == "_blank") {
		return (
			<a id={id} ref={ref} href={href} {...rest}>
				<TooltipWrap content={props.tooltip}>{props.children}</TooltipWrap>
			</a>
		)
	}

	if (wrap) {
		return (
			<Link id={id} legacyBehavior href={href} passHref>
				<a ref={ref} {...rest}>
					<TooltipWrap content={props.tooltip}>{props.children}</TooltipWrap>
				</a>
			</Link>
		)
	}

	return (
		<Link id={id} ref={ref} legacyBehavior href={href} passHref onClick={props.onClick}>
			<TooltipWrap content={props.tooltip}>{props.children}</TooltipWrap>
		</Link>
	)
})
Linky.displayName = "Linky"

function TooltipWrap({ content, children }) {
	return <>{content ? <Tooltip content={content}>{children}</Tooltip> : children}</>
}
