import { EmbedHologram } from "@/lib/hologramProps"
import { getHopsImage } from "lib/getHopsImage"
import { Client, ShowMessage, WipeMessage } from "lib/holoplaycore.module.js"
import { isMobile } from "react-device-detect"
import { useCastStore } from "./useCastStore"

/*
TODO: use the function below to detect the presence of HoloPlayService and make
importing holoplaycore a dynamically loaded library.

function isHoloplayServiceRunning() {
  return new Promise((resolve, reject) => {
    let socket = new WebSocket("ws://localhost:11222/driver", ['rep.sp.nanomsg.org'])
    socket.binaryType = 'arraybuffer'
    socket.onopen = function(e) {
      socket.close()
      resolve(true)
    }
    socket.onerror = function(e) {
      socket.close();
      resolve(false)
    }
  });
}
*/

export function useHoloPlayCore() {
	const castStore = useCastStore()

	function checkLegacyServiceConnection() {
		sendLookingGlassMessage({
			client: castStore.holoplayClient,
			setClient: castStore.setHoloPlayClient,
			onError: castStore.onError,
		})
	}

	const sendToDevice = (hologram: EmbedHologram): void => {
		// if (error) return
		if (isMobile) return

		castStore.onCastPending()

		const { quiltCols, quiltRows, quiltTileCount, aspectRatio } = hologram
		const quiltImage = getHopsImage(hologram)

		if (!quiltImage?.url) throw new Error("HoloPlayCore: No quilt image found")
		if (!quiltRows) throw new Error("HoloPlayCore: quiltRows not set")
		if (!quiltCols) throw new Error("HoloPlayCore: quiltCols not set")
		if (!quiltTileCount) throw new Error("HoloPlayCore: quiltTileCount not set")
		if (!aspectRatio) throw new Error("HoloPlayCore: aspectRatio not set")

		// Debug
		// onConnect()
		// castStore.setCasting(true)
		// return
		sendLookingGlassMessage({
			client: castStore.holoplayClient,
			setClient: castStore.setHoloPlayClient,
			onConnect: async (client) => {
				// castStore.onConnect({ major: 1, minor: 2 })

				// client creation success
				const response = await fetch(quiltImage.url)
				const arraybuf = await response.arrayBuffer()

				const showCmd = new ShowMessage(
					{ vx: quiltCols!, vy: quiltRows!, vtotal: quiltTileCount!, aspect: aspectRatio! },
					new Uint8Array(arraybuf),
					0,
				)
				await client.sendMessage(showCmd)
			},
			onError: castStore.onError,
		})
	}

	async function wipeFromDevice() {
		// if (error) return
		if (isMobile) return

		castStore.onCastTerminated()

		// Debugging
		// onConnect()
		// castStore.setCasting(false)
		// return

		sendLookingGlassMessage({
			client: castStore.holoplayClient,
			setClient: castStore.setHoloPlayClient,
			onConnect: async (client) => {
				const wipeCmd = new WipeMessage(0)
				await client.sendMessage(wipeCmd)
			},
			onError: castStore.onError,
		})
	}

	return {
		checkLegacyServiceConnection,
		sendToLookingGlass: sendToDevice,
		wipeFromLookingGlass: wipeFromDevice,
		error: castStore.onError,
	}
}

interface Args {
	client: Client | null
	setClient: (client: Client | null) => void
	onConnect?: (client: Client) => void
	onError?: (client: Client, error: any) => void
}

export function sendLookingGlassMessage({ client, setClient, onConnect: connected, onError: error }: Args) {
	if (!client) {
		client = new Client(
			async () => {
				connected?.(client!)
			},
			(err) => {
				error?.(client!, err)
			},
			() => {},
			false,
			"Blocks",
			true,
			"none",
		)
		setClient(client)
	} else {
		connected?.(client)
	}
}
