// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"753a72f39407feeb7917a46335dc9caf8bd43b1a"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs"
import { TEST_EMAIL_USER_REGEX } from "lib/utils"

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
	dsn: SENTRY_DSN,
	environment: process.env.VERCEL_ENV,
	tunnel: "/monitoring",
	normalizeDepth: 7,

	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 0.03,

	// Setting this option to true will print useful information to the console while you're setting up Sentry.
	debug: process.env.VERCEL_ENV === "development",

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.05,
	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// You can remove this option if you're not planning to use the Sentry Session Replay feature:
	integrations: [],

	ignoreErrors: [
		/Invariant: attempted to hard navigate to the same URL/g,
		/Support for defaultProps/g,
		/Cannot read properties of null (reading 'useState')/g,
	],

	beforeSend(event) {
		const isUnitTest = event.request?.headers?.["x-unit-test"] === process.env.BLOCKS_API_SECRET_TOKEN
		if (isUnitTest) return null

		if (event.exception?.values?.find((v) => v.type === "TRPCError")) {
			return null
		}

		if (!event.user) {
			return event
		}

		const email = event.user.email
		if (email?.match(TEST_EMAIL_USER_REGEX)) {
			// Ignore test users
			return null
		}
		return event
	},
})
