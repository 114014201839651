export const getS3Client = async () => {
	const { S3Client } = await import("@aws-sdk/client-s3")

	if (!process.env.NEXT_AWS_ACCESS_KEY || !process.env.NEXT_AWS_SECRET_KEY) {
		throw new Error("Missing NEXT_AWS_ACCESS_KEY or NEXT_AWS_SECRET_KEY")
	}

	const s3 = new S3Client({
		region: process.env.NEXT_AWS_REGION,
		credentials: {
			accessKeyId: process.env.NEXT_AWS_ACCESS_KEY,
			secretAccessKey: process.env.NEXT_AWS_SECRET_KEY,
		},
	})
	return s3
}
