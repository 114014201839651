import { Menu, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/24/solid"
import { Linky } from "components/Linky"
import { NavigationItem } from "./navigation"
import { twMerge } from "tailwind-merge"
import { Fragment } from "react"
import { classNames } from "lib/classNames"

export default function DesktopNavLink(props: NavigationItem & { className?: string }) {
	const { name, href, openNewTab, onClick, menu, className } = props

	if (menu) {
		return (
			<Menu as={"div"} key={`desktop-${name}-menu`}>
				{({ open }) => (
					<>
						<Menu.Button
							key={`desktop-${name}-link`}
							as="div"
							style={{
								textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
							}}
							className={"flex min-w-fit cursor-pointer items-center gap-1 text-sm font-medium  "}>
							<p className="hover:text-violet-500">{name}</p>
							<div className="relative h-4 w-4">
								<ChevronDownIcon
									className={classNames(
										!open ? "-top-2 opacity-0 duration-[0.3s]" : "duration-[0.7s]",
										"absolute top-0 ml-1 h-4 w-4 rotate-180 transition-all group-hover:text-violet-300 dark:fill-white",
									)}
									aria-hidden="true"
								/>
								<ChevronDownIcon
									className={classNames(
										open ? "top-2 opacity-0 duration-[0.3s]" : "duration-[0.7s]",
										"absolute top-0 ml-1 h-4 w-4 transition-all duration-300 group-hover:text-violet-300 dark:fill-white",
									)}
									aria-hidden="true"
								/>
							</div>
						</Menu.Button>
						<Transition
							as={Fragment}
							enter="transition ease-out duration-200"
							enterFrom="opacity-0 translate-y-1"
							enterTo="opacity-100 translate-y-0"
							leave="transition ease-in duration-150"
							leaveFrom="opacity-100 translate-y-0"
							leaveTo="opacity-0 translate-y-1">
							<Menu.Items
								className={
									"lkg-container-modal-solid absolute top-[70px] z-10 flex origin-top -translate-x-1/2  flex-col justify-start gap-4 whitespace-nowrap p-3 outline-none"
								}>
								{menu.map((link) => (
									<Menu.Item
										as={Linky}
										href={link.href}
										className={
											"group flex justify-around gap-4 rounded-lg !bg-opacity-5 p-3 transition duration-150 ease-in-out hover:cursor-pointer hover:bg-black hover:dark:bg-white"
										}
										onClick={link.onClick}
										key={link.name + "-nav-link"}>
										{link.Icon && (
											<link.Icon className="h-8 w-8  flex-shrink-0 fill-dark-purple-100 hover:fill-purple-500 dark:fill-white group-hover:dark:fill-white" />
										)}
										<div className="flex flex-grow flex-col gap-1">
											<p
												className={
													"hover:-text-gray-900 text-base font-medium text-gray-900 transition dark:text-white dark:group-hover:text-white"
												}>
												{link.name}
											</p>
											{link.description && (
												<p className="w-[20rem] whitespace-normal text-sm text-black !text-opacity-50 dark:text-white">
													{link.description}
												</p>
											)}
										</div>
									</Menu.Item>
								))}
							</Menu.Items>
						</Transition>
					</>
				)}
			</Menu>
		)
	}

	return (
		<Linky
			href={href}
			onClick={onClick}
			target={openNewTab ? "_blank" : undefined}
			key={`desktop-${name}-link`}
			style={{
				textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
			}}
			className={twMerge("text-sm font-medium  ", className)}>
			<p className="hover:text-violet-500">{name}</p>
		</Linky>
	)
}
