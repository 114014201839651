import { classNames } from "lib/classNames"
import styles from "./CastIcon.module.css"

interface Args {
	animate?: boolean
	className?: string
	reference?: React.RefObject<SVGSVGElement>
	fillColor?: string
}

export default function CastIcon({ animate, className = "", reference, fillColor }: Args) {
	return (
		<svg
			ref={reference}
			width="25"
			height="25"
			viewBox="0 2 25 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={classNames(
				"drop-shadow-lg",
				`${fillColor ? fillColor : "fill-white"}`,
				className,
				animate ? styles.gradient : "",
			)}>
			<defs>
				<linearGradient id="MyGradient" fy="90%">
					{/* <stop offset="5%" stopColor="#F60" />
					<stop offset="95%" stopColor="#FF6" />
					<animate attributeName="fy" dur="700ms" from="90%" to="0%" repeatCount="indefinite" /> */}
					<stop offset="0%" stopColor="lightblue" stopOpacity="1">
						<animate
							attributeName="stop-color"
							values="#4AC7FA;#E649F5;#4AC7FA"
							// @apply bg-gradient-to-r from-[#4AC7FA] to-[#E649F5];
							dur="5s"
							repeatCount="indefinite"
						/>
					</stop>
				</linearGradient>
			</defs>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.5 8.60827V15.9883C21.5 16.914 21.0114 17.7626 20.2143 18.2126L13.7857 21.9154C13.3871 22.1468 12.95 22.2626 12.5 22.2626C12.05 22.2626 11.6129 22.1468 11.2143 21.9154L10.5018 21.505C10.5022 21.3474 10.4894 21.1862 10.4624 21.0222C10.301 20.0291 9.95877 19.0981 9.47085 18.2635L11.61 19.544V13.0311L5.67 9.63398V14.9113C5.07937 14.6591 4.45256 14.4751 3.79958 14.3692L3.78786 14.3673L3.77611 14.3656C3.68316 14.3516 3.59107 14.3422 3.5 14.3372V8.60827C3.5 7.68256 3.98857 6.83398 4.78571 6.37113L11.2143 2.68113C11.6129 2.4497 12.05 2.33398 12.5 2.33398C12.95 2.33398 13.3871 2.4497 13.7857 2.68113L20.2143 6.38398C21.0114 6.83398 21.5 7.68256 21.5 8.60827ZM8.17037 20.1621L4.78571 18.2126C4.09408 17.8221 3.63474 17.1316 3.52526 16.3511L3.53233 16.3523C3.78632 16.3958 4.03494 16.455 4.27714 16.529C6.06764 17.0757 7.50709 18.4263 8.17037 20.1621ZM6.81 7.83684L12.5 11.204L18.19 7.83684L12.5 4.40398L6.81 7.83684ZM13.39 19.544L19.33 15.9883V9.63398L13.39 13.0311V19.544Z"
			/>
			<path d="M2.5 22.334L2.5 19.7332C3.93868 19.7332 5.10002 20.8949 5.10002 22.334L2.5 22.334ZM7.62205 22.334C7.19738 22.334 6.84204 22.0219 6.77271 21.5971C6.4607 19.7938 5.03936 18.3721 3.23667 18.06C2.812 17.9906 2.5 17.6352 2.5 17.2104C2.5 16.6815 2.95934 16.2654 3.47934 16.3434C6.0447 16.7596 8.07272 18.7795 8.48872 21.3457C8.57539 21.8658 8.15072 22.334 7.62205 22.334Z" />
		</svg>
	)
}
