import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { isMobile } from "react-device-detect"
import { ToastOptions } from "react-hot-toast"

export const toastSuccessStyle: ToastOptions = {
	className: "glass",
	icon: <CheckIcon className="mx-2 w-6 stroke-[#4EC437]" />,
	style: { backgroundColor: "#6a68a880", color: "white" },
	position: isMobile ? "bottom-center" : "bottom-right",
}

export const toastErrorStyle: ToastOptions = {
	className: "glass",
	icon: <XMarkIcon className="mx-2 w-6 stroke-[red]" />,
	style: { backgroundColor: "#6a68a880", color: "white" },
	position: isMobile ? "bottom-center" : "bottom-right",
}
