import { UserProfileImage } from "components/UserProfileImage"
import CompassIcon from "components/icons/CompassIcon"
import LibraryIcon from "components/icons/LibraryIcon"
import { Reorder } from "framer-motion"
import { useBlocksUserStore } from "hooks/useStore"
import { NextRouter, useRouter } from "next/router"
import { useCallback, useEffect, useState } from "react"
import { twMerge } from "tailwind-merge"

const getNavState = (router: NextRouter) => {
	switch (router.pathname) {
		case "/discover": {
			return ["selected", "gap-1", "gap-2"]
		}
		case "/library": {
			return ["gap-1", "selected", "gap-2"]
		}
		case "/[username]": {
			return ["gap-1", "gap-2", "selected"]
		}
		default: {
			return ["gap-1", "gap-2", "gap-3"]
		}
	}
}

export default function MobileNavFooter() {
	const user = useBlocksUserStore((store) => store.dbUser)
	const router = useRouter()

	const [items, setItems] = useState(getNavState(router))
	const footerClassName =
		" fixed bottom-0 pb-2 h-[64px] flex w-full items-center justify-around z-[9999] sm:hidden pointer-events-auto transition-opacity duration-200"
	const footerItemClassName =
		"flex-grow h-full flex items-center flex-1 justify-center cursor-pointer active:scale-90 transition-transform"

	const onNavClick = useCallback(
		(pathname: string) => {
			if (pathname === router.pathname) {
				return
			} else {
				router.push(pathname)
			}
		},
		[router],
	)

	useEffect(() => {
		const onRouteChangeComplete = () => {
			setItems(getNavState(router))
		}
		router.events.on("routeChangeComplete", onRouteChangeComplete)
		return () => {
			router.events.off("routeChangeComplete", onRouteChangeComplete)
		}
	}, [router])

	return (
		<div id="mobile-nav-footer" className={twMerge(footerClassName)}>
			<button
				className={footerItemClassName}
				onClick={() => {
					setItems(["selected", "gap-1", "gap-2"])
					onNavClick("/discover")
				}}>
				<CompassIcon className="h-8 w-8" />
			</button>
			<button
				className={footerItemClassName}
				onClick={() => {
					setItems(["gap-1", "selected", "gap-2"])
					onNavClick("/library")
				}}>
				<LibraryIcon className="h-8 w-8" />
			</button>
			<button
				className={footerItemClassName}
				onClick={() => {
					setItems(["gap-1", "gap-2", "selected"])
					onNavClick(`/${user?.username}`)
				}}>
				<UserProfileImage className="h-8 w-8" user={user} />
			</button>

			<Reorder.Group
				className="absolute bottom-0 -z-10 flex h-full w-full items-center justify-around  pb-2"
				style={{
					background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 67.66%)",
				}}
				axis="x"
				values={items}
				onReorder={setItems}>
				{items.map((item) => (
					<Reorder.Item key={item} value={item} className="flex h-full flex-1 justify-center">
						{item != "selected" ? (
							<div className="w-8"></div>
						) : (
							<div className="bg-purple-fade flex rounded-[30px] px-8 py-3 opacity-80">
								<div className="w-8"></div>
							</div>
						)}
					</Reorder.Item>
				))}
			</Reorder.Group>
		</div>
	)
}
