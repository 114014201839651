import { type Auth0Server, initAuth0 } from "@auth0/nextjs-auth0"
import { type IncomingMessage } from "http"
import { NextApiRequest } from "next"

const instances = new Map<string, Auth0Server>()

export function getReturnTo(req: NextApiRequest) {
	let returnTo = "/"
	if (req.query && req.query.returnTo) {
		returnTo = req.query?.returnTo.toString()
	}
	return returnTo
}

export function getAuth0Urls(req: IncomingMessage) {
	const host = req.headers ? req.headers["x-forwarded-host"] ?? req.headers["host"] : undefined

	if (!host) throw new Error("Missing host in headers")

	const path = getReturnTo(req as NextApiRequest)

	const protocol =
		process.env.VERCEL_URL || process.env.NGROK_URL?.includes(host as string) ? "https" : "http"
	const redirectUri = `${protocol}://${host}/api/auth/callback`
	const returnTo = `${protocol}://${host}${path}`
	const baseURL = `${protocol}://${host}`
	const signup = !!(req as NextApiRequest).query?.signup

	return {
		baseURL,
		redirectUri,
		returnTo,
		signup,
	}
}

export function getAuth0Instance(req: IncomingMessage) {
	const { baseURL } = getAuth0Urls(req)

	let instance = instances.get(baseURL)

	if (!instance) {
		instance = initAuth0({ baseURL })
		instances.set(baseURL, instance)
	}

	return instance
}
