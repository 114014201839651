import type { Prisma } from "@prisma/client"
import { getCDNUrl, getSourceImage } from "./cdn"
import { EmbedHologram } from "./hologramProps"

export const TEST_EMAIL_USER_REGEX = /test-user-[a-zA-Z0-9]{0,7}@lookingglassfactory\.com/g

export function getCenterRectFromQuilt(quilt: EmbedHologram, sourceImage: { width: number; height: number }) {
	const rows = quilt.quiltRows || 9
	const cols = quilt.quiltCols || 5
	const tileCount = quilt.quiltTileCount || 45

	const idx = Math.round((tileCount - 1) / 2)

	const w = Math.round(sourceImage.width / cols)
	const h = Math.round(sourceImage.height / rows)

	const xPos = (idx % cols) * w
	const yPos = sourceImage.height - (Math.floor(idx / cols) + 1) * h

	return [xPos, yPos, w, h].join("-")
}

export const getThumbnailUrl = (
	hologram: Prisma.HologramGetPayload<{ include: { imageAssets: true } }>,
	width = 300,
	height = 300,
) => {
	// @ts-ignore
	const sourceImage = getSourceImage(hologram)
	let rect: number[] | undefined = undefined

	if (hologram.type === "QUILT") {
		// @ts-ignore
		const coords = getCenterRectFromQuilt(hologram, sourceImage)
		rect = coords.split("-").map(Number)
	}

	return getCDNUrl(sourceImage!.url, {
		width,
		height,
		rect: rect ? { x: rect[0], y: rect[1], w: rect[2], h: rect[3] } : undefined,
	})
}
