import { Linky } from "components/Linky"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { twMerge } from "tailwind-merge"

export default function SignUpOrLogin(props: { className?: string }) {
	const { className } = props
	const router = useRouter()
	const [loginLink, setLoginLink] = useState("/api/auth/login")

	useEffect(() => {
		const handleLoginLink = (url) => {
			if (url === "/") {
				setLoginLink("/api/auth/login?returnTo=/discover")
			} else {
				setLoginLink("/api/auth/login")
			}
		}
		router.events.on("routeChangeComplete", handleLoginLink)

		handleLoginLink(router.pathname)
		return () => {
			router.events.off("routeChangeComplete", handleLoginLink)
		}
	}, [router])
	return (
		<span className={twMerge("flex gap-4 whitespace-nowrap text-sm text-white", className)}>
			<Linky href={"/api/auth/login?signup=true"} className="rounded-lg px-1 py-2 text-white hover:bg-foil">
				Sign up
			</Linky>
			<Linky href={loginLink} className="rounded-lg px-1 py-2 text-white hover:bg-foil">
				Log in
			</Linky>
		</span>
	)
}
