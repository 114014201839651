import { QuiltHologram, RGBDHologram } from "@lookingglass/bridge"
import {
	HOLOGRAM_DEFAULT_RGBD_FOCUS,
	HOLOGRAM_DEFAULT_RGBD_ZOOM,
	HOLOGRAM_DEFAULT_RGBD_DEPTHINESS,
	HOLOGRAM_DEFAULT_QUILT_COLS,
	HOLOGRAM_DEFAULT_QUILT_ROWS,
} from "prisma/models"
import { GetCastHologramResult } from "server/routers/hologram.trpc"

type NoNullCastHologram = Exclude<GetCastHologramResult, undefined>

interface PrismaToBridgeDepthArgs {
	hologram: NoNullCastHologram
	playlistId: number
	playlistUUID?: string | null
	playlistItemId: number
}

export function PrismaToBridgeQuilt(
	args: PrismaToBridgeDepthArgs,
	playlistUUID: string | null = null,
): QuiltHologram | undefined {
	const imageAssets = args.hologram.imageAssets
	let sourceImage = imageAssets
		.filter((image) => image.kind === "SOURCE")
		.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0]
	if (!sourceImage) {
		sourceImage = imageAssets
			.filter((image) => !image.type.includes("video") && !image.type.includes("gif"))
			// get the most recent hologram, since we can have multiple source images
			.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0]
	}
	if (!sourceImage) {
		new Error("No source image for hologram ID" + args.hologram.id)
		return undefined
	}

	const uri = sourceImage.url
	const rows = args.hologram.quiltRows ?? HOLOGRAM_DEFAULT_QUILT_ROWS
	const columns = args.hologram.quiltCols ?? HOLOGRAM_DEFAULT_QUILT_COLS
	let viewCount = args.hologram.quiltTileCount ?? rows * columns
	const aspect = args.hologram.aspectRatio
	const focus = args.hologram.rgbdFocus ?? HOLOGRAM_DEFAULT_RGBD_FOCUS
	const zoom = args.hologram.rgbdZoom ?? 0.5
	const crop_pos_x = args.hologram.crop_pos_x ?? 0
	const crop_pos_y = args.hologram.crop_pos_y ?? 0

	if (!uri || !rows || !columns || !aspect) {
		new Error("Missing Quilt property", { cause: { uri, rows, columns, viewCount, aspect } })
		return undefined
	}

	if (viewCount == null) {
		viewCount = columns * rows
	}

	const result = new QuiltHologram({
		uri,
		settings: {
			rows,
			columns,
			aspect,
			viewCount,
			crop_pos_x,
			crop_pos_y,
			focus: focus * 0.03,
			zoom: zoom * 2,
			tag: JSON.stringify({
				id: args.hologram.id,
				type: args.hologram.type,
				playlistID: args.playlistId,
				hologramUUID: args.hologram.uuid,
				playlistUUID: playlistUUID,
				playlistItemId: args.playlistItemId,
			}),
		},
	})
	return result
}

export function PrismaToBridgeDepth(
	args: PrismaToBridgeDepthArgs,
	playlistUUID: string | null = null,
): RGBDHologram | undefined {
	const RGBD = args.hologram.imageAssets.find((image) => image.kind === "RGBD")
	if (!RGBD) {
		new Error("No RGBD image for hologram ID" + args.hologram.id)
		return undefined
	}
	const { url } = RGBD

	const uri = url
	const depthiness = args.hologram.rgbdDepthiness ?? HOLOGRAM_DEFAULT_RGBD_DEPTHINESS
	const focus = args.hologram.rgbdFocus ?? HOLOGRAM_DEFAULT_RGBD_FOCUS
	const zoom = args.hologram.rgbdZoom ?? HOLOGRAM_DEFAULT_RGBD_ZOOM
	const aspect = args.hologram.aspectRatio ?? 1.0
	const crop_pos_x = args.hologram.crop_pos_x ?? 0
	const crop_pos_y = args.hologram.crop_pos_y ?? 0

	if (!uri || aspect == null) {
		new Error("Missing Depth Image properties", { cause: { uri, depthiness, focus, zoom, aspect } })
		return undefined
	}

	const result = new RGBDHologram({
		uri,
		settings: {
			depthiness: depthiness * 4,
			focus: focus * 0.03,
			zoom: zoom * 2,
			aspect: aspect,
			chroma_depth: 0,
			depth_inversion: 0,
			depth_loc: 2,
			depth_cutoff: 0,
			crop_pos_x,
			crop_pos_y,
			tag: JSON.stringify({
				id: args.hologram.id,
				type: args.hologram.type,
				playlistId: args.playlistId,
				hologramUUID: args.hologram.uuid,
				playlistUUID: playlistUUID,
				playlistItemId: args.playlistItemId,
			}),
		},
	})
	return result
}
