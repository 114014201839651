import { IconArgs } from "./FacebookIcon"

export default function PortraitIcon(args: IconArgs) {
	return (
		<svg
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 340 340"
			xmlSpace="preserve"
			className={args.className}>
			<g>
				<polygon className="st0" points="130.8,64.5 125.7,60.5 77,235.1 85,230.6  " />
				<polygon className="st0" points="232.2,64.5 241.3,58.4 128.4,57.6 134.4,62.5  " />
				<polygon className="st0" points="78,237.6 84.9,254 203.7,282.4 189.2,263.9  " />
				<polygon className="st0" points="223.6,259.8 242.3,241.7 242.3,240.8 229,238.2  " />
				<polygon className="st0" points="224.8,97.5 162,94.7 121.9,238.4 188.6,253.6  " />
				<polygon className="st0" points="98.5,232.4 112.9,235.8 153.1,91.2 141.7,80  " />
				<polygon className="st0" points="229.4,77.4 146.8,75.8 159.5,86.8 226.5,88.9  " />
				<polygon className="st0" points="228.8,164.2 231.4,165.1 233.9,154.3 231.2,153.4  " />
				<polygon className="st0" points="244.2,105.7 246.9,106.6 249.3,95.6 246.7,94.7  " />
				<path
					className="st0"
					d="M245,59.7l-49.4,201.8l15,19.7l3.6-14.7l7.5-31.3L263,69.6L245,59.7z M235.4,168.6c-0.3,0.4-0.6,1.2-1.2,1.3   c-0.4,0.4-1.2,0.4-1.8,0.3l-6.9-2.2c-1.2-0.4-1.8-1.5-1.5-2.7l3.6-15.5c0.3-0.4,0.6-1.2,1.2-1.3c0.4-0.4,1.2-0.4,1.8-0.3l6.9,2.2   c1.2,0.4,1.5,1.5,1.5,2.7L235.4,168.6z M244.4,132.8c-0.3,0.4-0.6,1.2-1.2,1.3c-0.4,0.4-1.2,0.4-1.8,0.3l-6.9-2.2   c-1.2-0.4-1.8-1.5-1.5-2.7l3.6-15.5c0.3-0.4,0.6-1.2,1.2-1.3c0.4-0.4,1.2-0.4,1.8-0.3l6.9,2.2c1.2,0.4,1.5,1.5,1.5,2.7L244.4,132.8   z M250.5,110.2c-0.3,0.4-0.6,1.2-1.2,1.3c-0.4,0.4-1.2,0.4-1.8,0.3l-6.9-2.2c-1.2-0.4-1.8-1.5-1.5-2.7l3.6-15.5   c0.3-0.4,0.6-1.2,1.2-1.3c0.6-0.4,1.3-0.4,1.8-0.3l6.9,2.2c1.2,0.4,1.8,1.5,1.5,2.7L250.5,110.2z"
				/>
				<polygon className="st0" points="237.7,128.6 240.4,129.5 242.8,118.5 240.1,117.6  " />
			</g>
		</svg>
	)
}
