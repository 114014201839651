import { QueueInfo } from "../globals"
import { Holograms } from "../../prisma/models/Holograms.model"
import { cloudfrontInvalidate, s3UpdateFileName } from "../../lib/utils.aws"
import { probeImageUrl } from "../../lib/probeImage"
import { getUserDisplayName } from "../../lib/utils.user"
import createQueue from "../../queues/createQueue"

interface UpdateFileNameJob {
	hologramId: number
}

export const UpdateFileNameOnS3: QueueInfo<UpdateFileNameJob> = {
	concurrency: 3,
	queue: createQueue<UpdateFileNameJob>("UpdateFileName", {
		redisHost: "default",
	}),
	process: async (job, done) => {
		try {
			// Loaded in as dynamic import to avoid circular issue with Holograms.ts
			const prisma = (await import("../../lib/prisma")).default

			const { hologramId } = job.data
			const hologram = await prisma.hologram.findFirst({
				where: {
					id: hologramId,
				},
				include: {
					user: true,
				},
			})

			if (!hologram) {
				done(new Error("hologram not found"), null)
				return
			}

			const images = await Holograms(prisma).sourceImages(hologramId, false)
			const quilt = images?.[0]

			if (!quilt) {
				done(new Error("quilt not found"), null)
				return
			}

			const key = quilt.url.replace(`https://s3.amazonaws.com/${process.env.NEXT_AWS_S3_BUCKET_NAME}/`, "")

			// Hard fetch the detail about the image just in case the DB is out of date
			await job.log(`probing url: ${quilt.url}`)
			const quiltData = await probeImageUrl(quilt.url)
			const ext = quiltData ? quiltData.type : ".png" // if we don't know the quilt type, default to png

			const title = hologram.title
				.replace(/[^a-zA-Z0-9_\.\- ]+/gi, "") // remove extraneous characters
				.substring(0, 20) // limit to 20 characters

			const quiltSettings =
				hologram.type == "QUILT"
					? ` (qs${hologram.quiltCols}x${hologram.quiltRows}a${hologram.aspectRatio})`
					: ""

			const filename = `${hologram.id} - ${title} by ${getUserDisplayName(
				hologram.user,
			)} - Looking Glass Blocks${quiltSettings}.${ext}`

			await s3UpdateFileName(key, filename)
			await cloudfrontInvalidate(key)

			done(null, { filename })
		} catch (err) {
			done(err as Error, null)
		}
	},
}
