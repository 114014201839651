import { classNames } from "lib/classNames"
import { IS_LOCAL_DEV, IS_VERCEL_PREVIEW } from "lib/environment"

/**  Determine colors and text for the tag next to the logo saying "alpha" or "DEV" or "PREVIEW". */
const EnvironmentBadge = ({ className = "" }) => {
	let tagText: string = ""
	let tagBg: string = ""

	if (IS_VERCEL_PREVIEW) {
		tagText = "PREVIEW"
		tagBg = "bg-rose-600"
	} else if (IS_LOCAL_DEV) {
		tagText = "DEV"
		tagBg = "bg-rose-500"
	} else {
		tagText = "alpha"
		tagBg = "bg-holo-light dark:bg-holo5"
	}

	return (
		<span
			className={classNames(
				`ml-1 -translate-y-1 text-[8px] leading-[8px] ${tagBg} text-md inline-block rounded-sm p-1 font-bold `,
				className,
			)}>
			<span className={`text-white drop-shadow-md dark:text-dark-purple-200`}>{tagText}</span>
		</span>
	)
}

export default EnvironmentBadge
