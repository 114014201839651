import { useCastStore } from "hooks/useCastStore"
import { isDesktop } from "react-device-detect"
import { PropsWithChildren, useEffect } from "react"

interface BridgeProviderProps extends PropsWithChildren {}

export default function BridgeProvider({ children }: BridgeProviderProps) {
	const handleConnectToBridge = useCastStore((store) => store.handleConnectToBridge)

	useEffect(() => {
		if (window !== undefined && isDesktop) {
			handleConnectToBridge()
		}
	}, [])

	return <>{children}</>
}
