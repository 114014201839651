import { IS_PRODUCTION } from "lib/environment"
import Image from "next/image"
import LogoDark from "public/lkg-logo-dark.svg"
import EnvironmentBadge from "./EnvironmentBadge"

export default function Logo(args: { forceDarkMode?: boolean }) {
	const { forceDarkMode } = args
	return (
		<div className="flex gap-2">
			<Image src={LogoDark} alt={"Logo"} className="" />
			<div className="hidden sm:block">
				<p className={"bg-text holo5 dark:holo5 font-rubik text-sm font-normal"}>Looking Glass</p>
				<div>
					<span
						className={`bg-text font-rubik text-2xl font-bold leading-3 ${
							forceDarkMode ? "holo5" : "dark:holo5 text-black"
						}`}>
						Blocks
					</span>
					{!IS_PRODUCTION && <EnvironmentBadge />}
				</div>
			</div>
		</div>
	)
}
